import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector, } from 'react-redux';
import { CALCULATION_TYPES, LABELS_INDUSTRY_VALUE, PARTICLE_OPTION_VALUES, PARTICLE_PRESENT_RESPIRATOR, STORE_ACTION_TYPES } from "../../../../helper/constants";
import "./Particles.scss";
import { useTranslation } from "react-i18next";
import {REGEX_VALIDATION_INDUSTRY } from "../../../../helper/constants";
const Particles = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { region, respiratorType, industryValue, particlePresent, calculatorType } = useSelector((state: any) => state?.respirator?.calculator || {});
    const { SEARCH_BOX_MAX_LENGTH, RESPIRATOR_REGION, RESPIRATORS_TYPES } = useSelector((state: any) => state?.respirator?.config || {});

    //For Analytics 
    const setIndustryAnalyticsValue = useCallback((storeIndustryValue: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { [storeIndustryValue]: e?.target?.value?.replace(
            REGEX_VALIDATION_INDUSTRY.API_INPUT_FIELD_VALIDATION,"") } });
    }, [dispatch]);

    //On continue Click - will move to next stage if region and respirator filled  	
    const onYesButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {	
        e.preventDefault();	
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { particlePresent : "Yes" } });	
    }, [dispatch]);	

    //On back button Click - will move to prev stage if region and respirator filled  	
    const onNoButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {	
        e.preventDefault();	
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { particlePresent : "No"} });	
    }, [dispatch]);	

     //Flag for checking if any  type selected 
    const isButtonYesActive = useMemo(() => { return (particlePresent === PARTICLE_OPTION_VALUES.YES );}, [ particlePresent ]);
    const isButtonNoActive = useMemo(() => { return (particlePresent === PARTICLE_OPTION_VALUES.NO); }, [ particlePresent ]);

    return (
        <div className="particles">
             {calculatorType != CALCULATION_TYPES.SERVICE_LIFE &&
                <>
                <div className="title">{t(PARTICLE_PRESENT_RESPIRATOR.PARTICLE_HEADLINE)}<span className="mandatory"></span></div>
                <div className="btn-container pb-5">
                    {(region === RESPIRATOR_REGION.USA || region === RESPIRATOR_REGION.EMEA_APAC) && respiratorType === RESPIRATORS_TYPES.PAPR ?
                    <div className="btn btn-disable mx-0">{t(PARTICLE_PRESENT_RESPIRATOR.NO)} </div>
                    :
                    <div onClick={onNoButtonClick} className={isButtonNoActive ? "btn btn-default mx-0" : "btn btn-primary mx-0"}>{t(PARTICLE_PRESENT_RESPIRATOR.NO)} </div>
                    }
                    <div onClick={onYesButtonClick} className={isButtonYesActive ? "btn btn-default mx-0" : "btn btn-primary mx-0"}>{t(PARTICLE_PRESENT_RESPIRATOR.YES)}</div>
                </div>
                </>
            }   
            <div className="form-group">
                <label htmlFor="selectIndustry">{t(PARTICLE_PRESENT_RESPIRATOR.INDUSTRY_HEADLINE)} <span>({t(PARTICLE_PRESENT_RESPIRATOR.INDUSTRY_HEADLINE_OPTIONAL)})</span></label>
                <input type="text" className="form-control" id="selectIndustry" aria-describedby="industry" placeholder="" value={(industryValue)} onChange={setIndustryAnalyticsValue(LABELS_INDUSTRY_VALUE.INDUSTRY_VALUE)} maxLength={SEARCH_BOX_MAX_LENGTH}/>
                <p className="small">{t(PARTICLE_PRESENT_RESPIRATOR.HELPTEXT)}</p>
            </div>
        </div>
    );
};

export default Particles;