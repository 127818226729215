/**********************************************************************************************
StepNavigation.tsx - Progrss bar sub componenet
***********************************************************************************************/
import React from 'react';
import Step from './Step';
const StepNavigation: React.FC<{ activeIndex: any, labelArray: any }> = ({ activeIndex, labelArray }) => {
    return (
        <div>
            <div className="respirator-steps">
                <ul>
                    {labelArray?.map((item: any, index: number) =>
                        <Step
                            key={index}
                            index={index}
                            label={item}
                            activeIndex={activeIndex || 0}
                            completed={index <= activeIndex}
                        />
                    )}
                </ul>
            </div>
        </div>
    );
};
export default StepNavigation;
