//analytics.ts - Services to push anaylytic data 
import { addAnalytics } from "../assets/js/utils";
import { store } from "../store/respiratorStore";
export default class Analytics {
    public static sendAnalyticsData(eventName,additionalIno?:any) {
        // grab current state
        const state = store.getState();
        const calculator = state?.respirator?.calculator || {};
        const { ANLYTICS_EVENT_FLOWS, ANLYTICS_FIELD_KEYS } = state?.respirator?.config || {};
        let data: any = null;
        if (eventName && ANLYTICS_EVENT_FLOWS && calculator?.calculatorType) {
            data = {
                "event": eventName,
                "calculator": {
                    "flow": ANLYTICS_EVENT_FLOWS[calculator?.calculatorType]
                }
            };
            if (ANLYTICS_FIELD_KEYS && ANLYTICS_FIELD_KEYS?.length > 0 && calculator) {
                ANLYTICS_FIELD_KEYS?.filter(key => key?.event?.indexOf(eventName) > -1 && key?.caculationTypes?.indexOf(calculator?.calculatorType) > -1)?.map(key => {
                    if (key?.formFieldKey && key?.formFieldSubKey && calculator[key?.formFieldKey][key?.formFieldSubKey]) {
                        data["calculator"][key?.analyticsKey] = calculator[key?.formFieldKey][key?.formFieldSubKey];
                    }
                    else if (key?.formFieldKey && !key?.formFieldSubKey && calculator[key?.formFieldKey]) {
                        data["calculator"][key?.analyticsKey] = calculator[key?.formFieldKey];
                    }
                });
            }
            if (data["calculator"] && additionalIno) {
                data["calculator"]={...data["calculator"],...additionalIno};
            }           
            if (data && addAnalytics) {
                addAnalytics(data);               
            }
        }
    }
    public static sendAnalyticsDataForError(state,errorMessage,errorMetaInfo,isErrorAction,errorAction) {
        // grab current state    
        
        const errorType = errorMetaInfo?.endpointName || undefined;  
        const apiRequestUrl=errorMetaInfo?.url || "";
        const apiRequestInputs= errorMetaInfo?.originalArgs || "";  
        
        const calculator = state?.calculator || {};
        const { ANLYTICS_EVENT_FLOWS,ANLYTICS_EVENT_TYPES } = state?.config || {};
        let data: any = null;
        if (errorType && errorMessage && ANLYTICS_EVENT_FLOWS && calculator?.calculatorType) {
            data = {
                "event": ANLYTICS_EVENT_TYPES?.ERROR || "error",
                "calculator": {
                    "flow": ANLYTICS_EVENT_FLOWS[calculator?.calculatorType],
                    "step":calculator?.currentStep || "",
                    "errorType":errorType || "",
                    "errorMessage": errorMessage || "",
                    "apiRequestUrl": apiRequestUrl || "",
                    "apiRequestInputs":apiRequestInputs || ""

                }
            };
            if( data && isErrorAction && errorAction?.length>0)
            {
                data["event"]=ANLYTICS_EVENT_TYPES?.ERROR_ACTION || "error-action";
                data["calculator"]["errorAction"] = errorAction || "";
            }              
            if (data && addAnalytics) {
                addAnalytics(data);               
            }
        }
    }
}