/****************************************************************************
CalculatorHome.tsx - Home page of the respirator calculator application
*****************************************************************************/
import React, { useCallback, useMemo,useState } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
//import Breadcrumb from "../Breadcrumb/Breadcrumb";
import WarningModal from "../../../common/WarningModal/WarningModal";
import "./CalculatorSelection.scss";
import { resizeParent,scrollToParentTop } from "../../../../assets/js/utils";
import { STORE_ACTION_TYPES, LABELSKEYS_CALCULATOR_SELECTION, ARIA_LABEL,WARNING } from "../../../../helper/constants";
import { initialState } from '../../../../store/respiratorStore';

const CalculatorSelection = () => {
    
    //t for traslation files
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Modal Functionality:
    const [show, setShow] = useState(false);

    const calculatorType = useSelector((state: any) => state?.respirator?.calculator?.calculatorType || "");
    const { CALCULATION_TYPES_VALUES, FIELD_CONFIG_CALCULATION_TYPES,PAGE_NAVIGATION,DISABLED_CALCULATION_TYPES } = useSelector((state: any) => state?.respirator?.config || {});

    //Calculator Type Div Click: Trigerring on Calculator type selection  and storing the details  
    const onCalculatorTypeClick = useCallback((calcType: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        if (calcType?.length > 0)
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { ...initialState.calculator,calculatorType: calcType, termsAgreed: false } });
    }, [dispatch]);

    //Flag for checking if any calculation type selected 
    const isButtonsActive = useMemo(() => {
        return CALCULATION_TYPES_VALUES?.indexOf(calculatorType) > -1;
    }, [calculatorType, CALCULATION_TYPES_VALUES]);

    //Calculator Selection Button Click: Trigerring on Calculator type selection  and storing the details  
    const onContinueButtonClick = useCallback((isContinue: boolean) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);        	
        scrollToParentTop();
        e.preventDefault();
        if (isContinue && isButtonsActive) {
            if (DISABLED_CALCULATION_TYPES?.indexOf(calculatorType) < 0) {
                navigate(PAGE_NAVIGATION[calculatorType].Terms_Conditions);
            }
            else { setShow(true); }
        }
        else {
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { calculatorType: "", termsAgreed: false } });
        }
    }, [dispatch, navigate, isButtonsActive,calculatorType,PAGE_NAVIGATION,DISABLED_CALCULATION_TYPES]);
    //resize parent iframe
    resizeParent();
    return (
        <div className="main-wrapper" role="region" aria-label={t(ARIA_LABEL.RESPIRATOR_CALCULTAION)}>
           {/* <Breadcrumb />*/}
            <div className="main-heading">{t(LABELSKEYS_CALCULATOR_SELECTION.MAIN_HEADING)}</div>
            <div className="cs-container">
                <div className="heading">{t(LABELSKEYS_CALCULATOR_SELECTION.SUB_HEADING)}</div>
                <div className="content col-md-12">
                    <div className="select-options">
                        {FIELD_CONFIG_CALCULATION_TYPES?.map((e: any, i: any) => {
                            return (
                                <div key={`div${e.fieldKey}${i}`} onClick={onCalculatorTypeClick(e.fieldValue)} className={`option ${calculatorType === e.fieldValue ?"active":""} text-center`}>
                                    <img src={calculatorType === e.fieldValue ? `${e.hoverImgUrl}` : `${e.imgUrl}`} alt={e.fieldValue} />
                                    <div className="option-label"><span>{t(e.fieldHeaderLabelkey)}</span>
                                        {t(e.fieldLabelkey)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="btn-container mx-0 cta-desktop">
                        <div className={isButtonsActive ? "btnlg btn-secondary" : `btnlg btn-disabled`} onClick={onContinueButtonClick(true)} >{t(LABELSKEYS_CALCULATOR_SELECTION.CONTINUE)}</div>
                        <div className={isButtonsActive ? "btnlg btn-primary" : `btnlg btn-disabled`} onClick={onContinueButtonClick(false)}>{t(LABELSKEYS_CALCULATOR_SELECTION.CANCEL)}</div>
                    </div>
                </div>
            </div>
            <div className="btn-container mx-0 cta-mobile">
                <div className={isButtonsActive ? "btnlg btn-secondary" : `btnlg btn-disabled`} onClick={onContinueButtonClick(true)} >{t(LABELSKEYS_CALCULATOR_SELECTION.CONTINUE)}</div>
                <div className={isButtonsActive ? "btnlg btn-primary" : `btnlg btn-disabled`} onClick={onContinueButtonClick(false)}>{t(LABELSKEYS_CALCULATOR_SELECTION.CANCEL)}</div>
            </div>
            <Modal show={show}>
                <WarningModal showWarningIcon
                    isHtmlMessage
                    messageHeader={t(WARNING.DISABLED_FLOW_HEADLINE)}
                    message={t(WARNING.DISABLED_FLOW_MESSAGE)}
                    onClickYes={() => setShow(false)}
                    labelYes={t(WARNING.DISABLED_FLOW_BUTTON_TEXT)}
                />
            </Modal>
        </div>
    );
};

export default CalculatorSelection;