//calculation api calls 
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const calculationApi = createApi({
    reducerPath: 'calculationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/respirator/calculate',
    }),
    endpoints: (builder) => ({
        getCalculations: builder.query<{ response?: any, isSuccess: any, result: any }, any>({
            query: params => ({
                url: '/concentration',
                method: 'POST',
                body: params
            }),
            transformResponse: (response: { message: any }) => {
                if (response?.message?.isSuccess) {
                    return response.message;
                }
                else {

                    return response.message;
                }
            }
            ,
        }),
        getResult: builder.query<{ response?: any, isSuccess: any, result: any }, any>({
            query: params => ({
                url: '/result',
                method: 'POST',
                body: params
            }),
            transformResponse: (response: { message: any }) => {
                if (response?.message?.isSuccess) {
                    return response.message;
                }
                else {
                    return response.message;
                }
            }
            ,
        }),
    }),

});
export const { useGetCalculationsQuery, useGetResultQuery } = calculationApi;

