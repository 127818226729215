import React from "react";
import "../../Results/Results.scss";
import "../../Results/Terms/Terms.scss";
import "./ResultsPdf.scss";
import { useTranslation } from "react-i18next";
import logo from '../../../../../assets/images/Honeywell.jpg';
import { CALCULATION_TYPES, LABELKEYS_PDF, LABELKEYS_RESULTS_REVIEW, LABELKEYS_RESULTS_REVIEW_REGION_ITEMS } from "../../../../../helper/constants";
import { useSelector } from "react-redux";
const ResultsPdf = () => {

    const { t } = useTranslation();
    const { calculatorType, region } = useSelector((state: any) => state?.respirator?.calculator || {});
    const {RESULT_LINK_URL}= useSelector((state: any) => state?.respirator?.config || {});
    //Modal Functionality Ends
    return (
        <div className="rc-results-wrapper">
            <div id="rc-resultsPdf-page1" className="rc-results">

            </div>
            <div id="rc-resultsPdf-page2" className="terms">

       
            </div>
            {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ?
                <div id="pdfHeader" className="pdf-header">
                    <h2 className="header-title">{t(LABELKEYS_PDF.HEADLINE_SERVICE_LIFE_PDF)}</h2>
                </div>
                :
                <div id="pdfHeader" className="logo-container1 pdf-header">
                    <h2 className="header-title">{t(LABELKEYS_PDF.HEADLINE_SELECT_CARTRIDGE_PDF)}</h2>
                </div>
            }
            <div id="pdfFooter" className="pdf-footer">
               <div className="contact-info">
                    <p className="info-text">{t(LABELKEYS_PDF.EMAIL)} <a href={`mailto:${t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.EMAIL_ADDRESS, region) as string)}`}>{t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.EMAIL_ADDRESS, region) as string)}</a></p>
                    <p className="info-text">{t(LABELKEYS_PDF.TECHNICAL_SUPPORT)} {t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.REPRESENTATOR_CONTACT, region) as string)}</p>
                    <p><a href={RESULT_LINK_URL.SPS_HOME_URL}>{t(LABELKEYS_PDF.WEB_URL)}</a></p>
               </div>
               <div className="honeywell-logo">
                    <a href={RESULT_LINK_URL.SPS_HOME_URL} className="logo-link m-0">
                        <img className="logo-footer" src={logo} alt="Honeywell Logo" />
                    </a>
               </div>
            </div>
            <div className="table-for" id="tableFor"></div>
        </div>
    );
};
export default ResultsPdf;