import React, { useEffect } from "react";
import { ImgWarning } from "../../../assets/images/cartridge/cartSelection";
import "./WarningModal.scss";
import DOMPurify from 'dompurify';
import { scrollToParentTop } from "../../../assets/js/utils";
const WarningModal = (props) => {
    useEffect(()=>{
        window.scrollTo(0, 0);
        scrollToParentTop();
    },[]);
    return (
        <div className="warning-overlay">
            <div className="warning-container">
                <div className="warning-title"> {props?.showWarningIcon && <img src={ImgWarning} alt="warning" className="icon" />} {props?.messageHeader}</div>
                {props?.isHtmlMessage && <p className={props?.labelRed === 'true' ? 'label-red' : 'no-label-red'} dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize( props?.message || "") }} />}
                {!props?.isHtmlMessage && <p className = {props?.labelRed === 'true' ? 'label-red' : 'no-label-red'} dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize( props?.message || "") }} />}
                <div className="btn-container cta-desktop">
                    {
                        (props?.labelNo?.length > 0 &&
                            <div className="btn btn-primary" onClick={props.onClickNo}>{props.labelNo}</div>
                        )
                    }

                    {
                        (props?.labelYes?.length > 0 &&
                            <div className="btn btn-secondary" onClick={props.onClickYes}>{props.labelYes}</div>
                        )
                    }
                </div> 
                <div className="btn-container cta-mobile">
                    {
                        (props?.labelYes?.length > 0 &&
                            <div className="btn btn-secondary" onClick={props.onClickYes}>{props.labelYes}</div>
                        )
                    }
                    {
                        (props?.labelNo?.length > 0 &&
                            <div className="btn btn-primary" onClick={props.onClickNo}>{props.labelNo}</div>
                        )
                    }
                </div> 
                {props?.isDecriptionBox && (
                    <div className="ModalDecriptionBox">
                        <p dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize( props?.descriptionBoxMessage || "") }}></p>
                        <div className="btn btn-secondary" onClick={props.onClickOk}>{props.labelOk}</div>
                    </div>)}
            </div>
        </div>
    );
};
export default WarningModal;
