/**********************************************************************************************
RespiratorSelection.tsx - User can select region and respirator type
***********************************************************************************************/
import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation } from "react-i18next";
import "./RespiratorSelection.scss";
import Particles from "../Particles/Particles";
import { resizeParent } from "../../../../assets/js/utils";
import { USASelectedImg, ImgUSA, ImgEMEA, ImgReusable, ImgParr, ImgEMEASelected } from "../../../../assets/images/respselection/respSelection";
import { LABELKEYS_RESPIRATOR_SELECTION, STORE_ACTION_TYPES } from "../../../../helper/constants";

const RespiratorSelection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const calculator = useSelector((state: any) => state?.respirator?.calculator || {});
    const { region } = useSelector((state: any) => state?.respirator?.calculator || {});
    const { FIELD_CONFIG_RESPIRATOR_REGION_SELECTION, RESPIRATOR_REGION, FIELD_CONFIG_RESPIRATOR_TYPE_SELECTION, RESPIRATORS_TYPES } = useSelector((state: any) => state?.respirator?.config || {});

    //Region Click: Trigerring on region selection  and storing the details  
    const onRegionClick = useCallback((regionValue: string) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.preventDefault();
        if (regionValue?.length > 0)
        {
            if(regionValue === RESPIRATOR_REGION.EMEA_APAC)
            {
                dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { region: regionValue,cartridgeSKU:"",respiratorCartridgeSKU:"", respiratorType : "" } });
            }
            else
            {
                dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { region: regionValue,cartridgeSKU:"",respiratorCartridgeSKU:""} });
            }
        }
            
    }, [RESPIRATOR_REGION.EMEA_APAC, dispatch]);

    //Respirator Type Click: Trigerring on respirator type selection  and storing the details  
    const onRespiratorTypeClick = useCallback((respTypeValue: string) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.preventDefault();
        if (respTypeValue?.length > 0)
        {
            if(respTypeValue === RESPIRATORS_TYPES.PAPR)
            {
                dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { respiratorType: respTypeValue,cartridgeSKU:"",respiratorCartridgeSKU:"", particlePresent : "" } });
            }
            else
            {
                dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { respiratorType: respTypeValue,cartridgeSKU:"",respiratorCartridgeSKU:"" } });
            }
        }
           
    }, [RESPIRATORS_TYPES.PAPR, dispatch]);

    //Switching Region Images
    const switchRegionImage = useCallback((regionValue: string) => {
        switch (regionValue) {
            case RESPIRATOR_REGION?.USA:
                return ImgUSA;
                break;
            case RESPIRATOR_REGION?.USA_SELECTED:
                return USASelectedImg;
                break;
            case RESPIRATOR_REGION?.EMEA_SELECTED:
                return ImgEMEASelected;
                break;
            case RESPIRATOR_REGION?.EMEA_APAC:
                return ImgEMEA;
                break;
        }
    }, [RESPIRATOR_REGION?.EMEA_APAC, RESPIRATOR_REGION?.EMEA_SELECTED, RESPIRATOR_REGION?.USA, RESPIRATOR_REGION?.USA_SELECTED]);

    //Switching Respirator Type Images
    const switchRespiratorTypeImage = useCallback((respiratorTypeValue: string) => {
        switch (respiratorTypeValue) {
            case RESPIRATORS_TYPES?.APR:
                return ImgReusable;
            case RESPIRATORS_TYPES?.PAPR:
                return ImgParr;
        }
    }, [RESPIRATORS_TYPES]);

    const isImageUSAClicked = useMemo(() => {
        return (region === RESPIRATOR_REGION.USA);
    }, [region,RESPIRATOR_REGION]);

    const isImageEMEAClicked = useMemo(() => {
        return (region === RESPIRATOR_REGION.EMEA_APAC);
    }, [region,RESPIRATOR_REGION]);
    //resize parent iframe
    resizeParent(150);
    return (
        <div className="rs-container">
            <div className="heading">{t(LABELKEYS_RESPIRATOR_SELECTION.HEADING)}</div>
            <div className="selection-wrapper">
                <div className="res-region">
                    <div className="title pb-3">{t(LABELKEYS_RESPIRATOR_SELECTION.REGION)} <span className="mandatory"></span></div>
                    <div className="images-list">
                        <ul>
                            {FIELD_CONFIG_RESPIRATOR_REGION_SELECTION?.map((e: any, i: number) => {
                                return (
                                    <li key={`divRegions${e?.fieldKey}${i}`} onClick={onRegionClick(e?.fieldValue)} className={calculator[e?.fieldKey] === e?.fieldValue ? "active" : ""}>
                                        <div className="pic">
                                            <img src=
                                                {
                                                    e?.fieldValue === RESPIRATOR_REGION.USA ?
                                                        isImageUSAClicked ? switchRegionImage(e?.fieldValueSelected) : switchRegionImage(e?.fieldValue)
                                                        :
                                                        isImageEMEAClicked ? switchRegionImage(e?.fieldValueSelected) : switchRegionImage(e?.fieldValue)
                                                }
                                                alt={t(e?.fieldLabelkey)} />
                                        </div>
                                        <p className="sub-title">{t(e?.fieldLabelkey)}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="res-type">
                    <div className="title pb-3">{t(LABELKEYS_RESPIRATOR_SELECTION.TYPE)} <span className="mandatory"></span></div>
                    <div className="images-list">
                        <ul>
                            {FIELD_CONFIG_RESPIRATOR_TYPE_SELECTION?.map((e: any, i: number) => {
                                return (                                   
                                    region === RESPIRATOR_REGION.EMEA_APAC ?  
                                    (e?.emeaRegionDisable === true ?
                                    <li key={`divDisabledRespTypesForEmea${i}`} className={calculator[e?.fieldKey] === e?.fieldValue ? "disable" : "disable"}>
                                        <div className="pic"><img className="respiratorType" src={switchRespiratorTypeImage(e?.fieldValue)} alt={t(e?.fieldLabelkey)} /></div>
                                        <p className="sub-title">{t(e?.fieldLabelkeyValue)} <span>({t(e?.fieldLabelkey)})</span></p>
                                    </li>
                                    :
                                    <li key={`divRespTypesForEmea${i}`} onClick={onRespiratorTypeClick(e?.fieldValue)} className={calculator[e?.fieldKey] === e?.fieldValue ? "active" : ""}>
                                    <div className="pic"><img className="respiratorType" src={switchRespiratorTypeImage(e?.fieldValue)} alt={t(e?.fieldLabelkey)} /></div>
                                    <p className="sub-title">{t(e?.fieldLabelkeyValue)} <span>({t(e?.fieldLabelkey)})</span></p>
                                    </li>)
                                    :
                                    <li key={`divRespTypesForNonEmea${i}`} onClick={onRespiratorTypeClick(e?.fieldValue)} className={calculator[e?.fieldKey] === e?.fieldValue ? "active" : ""}>
                                        <div className="pic"><img className="respiratorType" src={switchRespiratorTypeImage(e?.fieldValue)} alt={t(e?.fieldLabelkey)} /></div>
                                        <p className="sub-title">{t(e?.fieldLabelkeyValue)} <span>({t(e?.fieldLabelkey)})</span></p>
                                    </li>         
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        <Particles />
        </div>
    );
};

export default RespiratorSelection;