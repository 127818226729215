
import React from "react";
import "./Terms.scss";
import { LABELSKEY_TERMS, LABELS_TERMS } from "../../../../../helper/constants";
import TermsConditionsContent from "../../TermsConditions/TermsConditionsContent";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';
const Terms = (props) => {
    const { t } = useTranslation();
    const { warningPDFContent } = useSelector((state: any) => state?.respirator?.calculator || {});
    const warningHTML = warningPDFContent || undefined;
    const strippedHtml = String(warningHTML)?.replace(/<[^>]+>/g, '') || "";
    return (
        <>
        <div id="terms" className="terms-sec">
            <div className="subhead pt-4">{LABELS_TERMS.HEADING}</div>
            <TermsConditionsContent customerServiceNo={props?.customerServiceNo ? " at " + props?.customerServiceNo : undefined} />
        </div>
        <div className="download-pdf-table">
            <table>
                <tr className="table-header">
                    {strippedHtml?.length > 0 ? <th>{t(LABELSKEY_TERMS.WARNINGS)}</th> : ''}
                    <th>{t(LABELSKEY_TERMS.HEADING)}</th>
                </tr>
                <tr>
                    {strippedHtml?.length > 0 ?
                    <td>
                        <div className="warning-pdf">
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(warningPDFContent) }} className="content"></p>
                        </div>                   
                    </td>
                    :
                    ''
                    }
                    <td> 
                        <TermsConditionsContent customerServiceNo={props?.customerServiceNo ? " at " + props?.customerServiceNo : undefined}/>
                    </td> 
                </tr>
            </table>
        </div>
    </>
    );
};

export default Terms;

   