import React from "react";
//import RespiratorSelection from "../RespSelection/RespiratorSelection";
// import CartridgeSelection from "../CartridgeSelection/CartridgeSelection";
// import Contaminant from "../Contaminant/Contaminant";
// import RespReview from "../RespReview/RespReview";
// import CartridgeReview from "../RespReview/CartridgeReview";
// import ContainmentReview from "../RespReview/ContainmentReview";
// import ConditionReview from "../RespReview/ConditionReview";
// import WorkingConditions from "../WorkingConditions/WorkingConditions";
// import Results from "../Results/Results";
// import WarningModal from "../../../common/WarningModal/WarningModal";
import ResultsReview from "../Reviews/ResultsReview";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./RespiratorSteps.scss";

import ProgressBar from '../../../common/ProgressBar/ProgressStepper';
import { RESPIRATOR_STEPS_COLL, INITIAL_STEP,CALCULATION_TYPES } from "../../../../helper/constants";
import Particles from "../Particles/Particles";

const StepsTest = () => {
    return (
        <div className="main-wrapper">
            <Breadcrumb />
            <div className="main-heading">Respirator Calculator</div>
            <ProgressBar step={RESPIRATOR_STEPS_COLL(CALCULATION_TYPES.SERVICE_LIFE)} completed={INITIAL_STEP} />
            <div className="row pt-4">
                <div className="col-md-9 col-sm-12">
                    {/*  <RespiratorSelection />*/}
                    {/* <CartridgeSelection /> */}
                    {/* <Contaminant /> */}
                    {/* <WorkingConditions /> */}
                    {/* <Results /> */}
                    {/* <WarningModal /> */}
                    <Particles />
                </div>
                <div className="col-md-3 col-sm-12">
                    {/*  <RespReview />*/}
                    {/* <CartridgeReview /> */}
                    {/* <ContainmentReview /> */}
                    {/* <ConditionReview /> */}
                    <ResultsReview />
                </div>
            </div>
        </div>
    );
};

export default StepsTest;