/**********************************************************************************************
CartridgeSelection.tsx - User can select product from the given list
***********************************************************************************************/
import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import "./CartridgeSelection.scss";
import "../Reviews/RespReview.scss";
import Loader from "../../../common/Loader/Loader";
import { ProductSelection } from "../../../../types/allTypes";
import {STORE_ACTION_TYPES, LABELKEYS_CATRIDGE_STEP, REGEX_VALIDATION, CALCULATION_TYPES } from "../../../../helper/constants";
import NoResults from "../../../common/NoResults/NoResults";
import { useGetRespiratorsQuery } from "../../../../services/respirators";
import SearchBox from "../../../common/SearchBox/SearchBox";
import { FallBackProductImage } from "../../../../assets/images/cartridge/cartSelection";
import ErrorHandling from "../../../common/ErrorHandling/ErrorHandling";
import { resizeParent } from "../../../../assets/js/utils";

const RespiratorCartridgeSelection: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { calculator } = useSelector((state: any) => state?.respirator || {});
  const { cartridgeSKU, respiratorCartridgeSKU } = useSelector((state: any) => state?.respirator?.calculator || {});
  const { showError } = useSelector((state: any) => state?.respirator?.error || {});
  const [productList, setProductList] = useState<any>([]);
  const [totalRecord, setTotalRecord] = useState(0); 
  const [searchTermFind, setsearchTerm] = useState("");
  const [visibleProductList, setVisible] = useState(0);
  const [defaultsearch, setSearch] = useState(false);
  const { CONFIG_DEFAULT_PRODUCT_LIST_ROWS, CONFIG_MIN_LETTERS_FOR_SEARCH_CARTRIDGE } = useSelector((state: any) => state?.respirator?.config || {});
  
  /*------Cartridge product selection inital state-----*/
  const [productState, setProductState] = useState<ProductSelection>({
    
    pageNumber: 1,
    rows: CONFIG_DEFAULT_PRODUCT_LIST_ROWS,
    sortBy: "productName",
    sortOrder: "asc",
    searchText: searchTermFind,
    searchField: "",
    region: calculator?.region,
    respiratorType: calculator?.respiratorType,
  });
  /*------Cartridge product selection inital state ends-----*/

  const { pageNumber, rows, searchText } = productState;
  /*------Product Query -----*/
  const { data: resultRespirators,isLoading } = useGetRespiratorsQuery(calculator?.calculatorType === CALCULATION_TYPES?.SERVICE_LIFE ? {
    productSku: cartridgeSKU,
    pageNumber: pageNumber,
    searchText: defaultsearch === true ? searchText : "",
    searchField: "",
    rows: rows,
    sortBy: "respirator",
    sortOrder: "asc"
  } : skipToken);
  /*------Product Query -----*/

  /*------useEffect Starts -----*/
  useEffect(() => {

    if (searchTermFind?.trim() == "") {
      /* ------when Search field is empty ---- */
      (pageNumber === 1 ?
        setProductList(resultRespirators?.result?.respiratorList)
        :
        (productList?.length > 0 ?
          setProductList([...productList, ...resultRespirators?.result?.respiratorList])
          :
          setProductList(resultRespirators?.result?.respiratorList))  //
      );
      pageNumber === 1 && setVisible((resultRespirators?.result?.totalRecords < Number(productState?.rows) ? resultRespirators?.result?.totalRecords : Number(productState?.rows)));
      setTotalRecord(resultRespirators?.result?.totalRecords);
      /* ----when Search field is empty ends--- */
    }
    else {
      /* ------  when Search field consist of any search Term ---- */
      resultRespirators?.result?.respiratorList === undefined && updateProductState('pageNumber', 1);
      (pageNumber === 1 ?
        setProductList(resultRespirators?.result?.respiratorList)
        :
        setProductList([...productList, ...resultRespirators?.result?.respiratorList])
      );
      pageNumber === 1 && setVisible((resultRespirators?.result?.totalRecords < Number(productState?.rows) ? resultRespirators?.result?.totalRecords : Number(productState?.rows)));
      setTotalRecord(resultRespirators?.result?.totalRecords);
      /* ---- when Search field consist of any search Term ends---- */
    }
    /* eslint-disable */
  }, [resultRespirators?.result?.respiratorList, searchTermFind]);
  /*------useEffect Completed -----*/

  /* ------- Search Functionality Click : Triggering the Search Button will display product based on search terms ------- */
  const findSearchTerm = (searchTermFind) => {
    if (searchTermFind?.length === 0 || searchTermFind?.length < 3) {
      updateProductState('pageNumber', 1);
    }
    if (searchTermFind?.length === 0 || searchTermFind?.length >= CONFIG_MIN_LETTERS_FOR_SEARCH_CARTRIDGE) {
      setSearch(true);
      setsearchTerm(searchTermFind);
      updateProductState('searchText', searchTermFind);
    }
  };
  /* -------- Search Functionality Ends ---------------------------------------------------------------------------------- */

  /* -------update Product State values ----- */
  const updateProductState = useCallback(
    (key: any, value: any) => {
      if (key) {
        setProductState({ ...productState, [key]: value });
      } else {
        setProductState({ ...productState, ...value });
      }
    },
    [productState]
  );
  /* -------update Product State ends ----- */

  /* ------- Load More Click: Trigerring on Load More button to display more products---------*/
  const LoadMoreProduct = () => {
    updateProductState('searchText', "");
    if (visibleProductList < totalRecord) {
      updateProductState('pageNumber', Number(productState?.pageNumber) + 1);
      if (visibleProductList + Number(productState?.rows) > totalRecord) {
        setVisible(totalRecord);
      }
      else {
        setVisible((prevValue) => prevValue + Number(productState?.rows));
      }
    }
    else {
      setVisible(totalRecord);
    }
  };
  /* ------- Load More Functionality ends----------------------------------------------------*/

  /* -------- Product List Click: Trigerring on product selection  and storing the details ------- */
  const onProductListClick = useCallback(
    (
      respiratorcartridgeSKUValue: string,
      respiratorImgUrlValue: string,
      respiratordescription: string,
      index: any
    ) =>
      (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.preventDefault();
        if (respiratorcartridgeSKUValue?.length > 0) {
          dispatch({
            type: STORE_ACTION_TYPES.CALCULATOR,
            payload: {
              respiratorCartridgeSKU: respiratorcartridgeSKUValue,
              respiratorImgUrl: respiratorImgUrlValue,
              respiratorDescriptionValue:respiratordescription
            },
          });
        }       
      },
    [dispatch]
  );
  /* -------- Product List Functionality Ends------------------------------------------------------ */

  /* --------------- Setting Search Value  ----------------- */
  const setSearchHandler = (event) => {
    setsearchTerm(
      event?.target?.value?.replace(
        REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
        ""
      )
    );
    findSearchTerm(
      event?.target?.value?.replace(
      REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
      ""
    )
    );
  }
  const debounce = (func) => {
    let timer;
    return function (this: any, ...args) {
      const context = this;
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    }
  }
  const optimisedCartridgeSearch = useCallback(debounce(setSearchHandler), []);
  /* --------------- Setting Search Value Ends ----------------- */
//resize parent iframe
resizeParent();
  return (
    <div className="cartridge-selection">
      {showError === false ?
        (
          <>
            <div className="heading" aria-hidden="true">
              {t(LABELKEYS_CATRIDGE_STEP.RESPIARTOR_CARTRIDGE_HEADING)}
            </div>

            {/* Calling SerachBox Component */}
            <SearchBox
              showStatus={productList?.length > 0}
              statusText={`${t(LABELKEYS_CATRIDGE_STEP.SHOWING)} ${visibleProductList} ${t(
                LABELKEYS_CATRIDGE_STEP.SHOWING_OF
              )} ${totalRecord} ${t(LABELKEYS_CATRIDGE_STEP.PRODUCT_RESULTS)}`}
              placeholder={t(LABELKEYS_CATRIDGE_STEP.RESPIRATOR_PLACEHOLDER)}
              onChange={optimisedCartridgeSearch}
              onClick={findSearchTerm}
              displayText={t(LABELKEYS_CATRIDGE_STEP.SEARCH)}
              disabled={searchTermFind.length < 3 ? true : false}
            />
            {/* SerachBox Component Ends*/}
            {isLoading && <Loader />}
            {resultRespirators?.result?.respiratorList === undefined && !isLoading && <NoResults />}
            <div className="products">
              <div className="images-list col-md-12">
                <ul className="col-md-12">
                  {productList?.slice(0, visibleProductList)?.map(
                    (
                      item: {
                        respirator: string;
                        respiratorImgUrl: string;
                        description : string
                      },
                      index: any
                    ) => {
                      return (
                        <li
                          key={index}
                          onClick={onProductListClick(
                            item.respirator,
                            item.respiratorImgUrl,
                            item.description,
                            index,
                          )}
                          className={item.respirator === respiratorCartridgeSKU ? "product-active col-md-3" : "col-md-3"}
                        >
                          <div>
                            <div className="pic">
                              <img src={item?.respiratorImgUrl || FallBackProductImage} onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src=FallBackProductImage;
                                }} alt={item.respirator} />
                            </div>
                            <div className="sku fw-bold">{item.respirator}</div>                           
                            <div className="">{item.description || ""}</div>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>

            {/* LoadMore Starts*/}
            <div className="btn-container">
              {((totalRecord - visibleProductList) > 0) ? (
                <div className="btnmd btn-primary" onClick={LoadMoreProduct}>
                  {t(LABELKEYS_CATRIDGE_STEP.LOAD_MORE)}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {/* LoadMore Ends*/}
          </>
        ) : <ErrorHandling />
      }
    </div>

  );
};

export default RespiratorCartridgeSelection;

