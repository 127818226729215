import React, { useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./RespReview.scss";
import jsPDF from "jspdf";
import ResultsPdf from "./PDF/ResultsPdf";
import Loader from "../../../common/Loader/Loader";
import { RESPIRATOR_STEPS, STORE_ACTION_TYPES, REVIEW_PANEL_ACTION, LABELKEYS_RESULTS_REVIEW, CALCULATION_TYPES, LABELKEYS_RESULTS_REVIEW_REGION_ITEMS, REVIEW_KEY_PANEL_ACTION } from "../../../../helper/constants";
import { scrollToParentTop } from "../../../../assets/js/utils";
import Analytics from "../../../../services/analytics";
const ResultsReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDownloading, setIsDownloading] = useState(false);
    const { concentration_Value_1, concentration_Value_2, concentrationValue, rateValue, temperatureValue, humidityValue, calculatorType, region } = useSelector((state: any) => state?.respirator?.calculator || {});
    const { DOWNLOAD_REPORT_FILENAME, RESULT_LINK_URL, DOWNLOADBUTTON_CONFIG, PAGE_NAVIGATION, REACT_APP_RESULTS_SUPPORT_LINK, REACT_APP_RESULTS_WHERETOBY_LINK, ANLYTICS_EVENT_TYPES } = useSelector((state: any) => state?.respirator?.config || {});

    //On back button Click - will move to prev stage if region and respirator filled  
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if (calculatorType === CALCULATION_TYPES.SERVICE_LIFE) {
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { concentration_Value_1: concentration_Value_1, concentration_Value_2: concentration_Value_2, concentrationValue: concentrationValue, rateValue: rateValue, temperatureValue: temperatureValue, humidityValue: humidityValue, currentStep: RESPIRATOR_STEPS.WORKING_CONDITION } });
            navigate(PAGE_NAVIGATION.ServiceLife.Working_Condition);
        }
        else if (calculatorType === CALCULATION_TYPES.VAPORS) {
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { concentration_Value_1: concentration_Value_1, concentration_Value_2: concentration_Value_2, concentrationValue: concentrationValue, rateValue: rateValue, temperatureValue: temperatureValue, humidityValue: humidityValue, currentStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION } });
            navigate(PAGE_NAVIGATION.Vapors.Product_Selection);
        }
    }, [calculatorType, concentrationValue, concentration_Value_1, concentration_Value_2, dispatch, humidityValue, rateValue, temperatureValue, navigate, PAGE_NAVIGATION.ServiceLife.Working_Condition, PAGE_NAVIGATION.Vapors.Product_Selection]);

    //On AngleUp and Down Arrow Event - will expand and collapse Review Panel
    const [state, setState] = useState({
        respClassName: "resp-review-mobile",
        downAngleiconClass: "resp-acrodian",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "resp-review-mobile") {
            setState({
                respClassName: "expand-resp-panel results-resp-panel",
                downAngleiconClass: "resp-acrodian-new",
                iconName: "Down"
            });
        }
        else {
            setState({
                respClassName: "resp-review-mobile",
                downAngleiconClass: "resp-acrodian",
                iconName: "Up"
            });
        }
    };

    //remove All ChildNodes
    const removeAllChildNodes = (parent) => {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    };
    //Download Pdf functionality for desktop as well as mobile
    const downloadPDF = () => {
        setIsDownloading(true);
        try {
            let pdfContentPage1: any = window.document.getElementById("rc-resultsPdf-page1") as HTMLCanvasElement;
            let pdfContentPage2: any = window.document.getElementById("rc-resultsPdf-page2") as HTMLCanvasElement;
            const termColumnConatent: any = window.document.getElementById("tableFor") as HTMLCanvasElement;
            removeAllChildNodes(pdfContentPage1);
            removeAllChildNodes(pdfContentPage2);
            removeAllChildNodes(termColumnConatent);

            const doc = new jsPDF("p", "pt", [950, 1300]);
            doc.setFont("Helvetica");
            const pdfSec1: any = window.document.getElementsByClassName("pdf-header")[0]?.cloneNode(true);
            pdfSec1.style.display = "flex";
            const pdfSec2: any = window.document.getElementsByClassName("pdf-page1-sec2")[0]?.cloneNode(true);
            pdfSec2.style.display = "flex";
            const pdfSec3: any = window.document.getElementsByClassName("pdf-page1-sec3")[0]?.cloneNode(true);
            const pdfSec4: any = window.document.getElementsByClassName("pdf-page1-sec4")[0]?.cloneNode(true);
            const pdfSec5: any = window.document.getElementsByClassName("pdf-footer")[0]?.cloneNode(true);
            pdfSec4.style.display = "flex";
            pdfSec5.style.position = "absolute";
            pdfSec5.style.top = "1100px";

            const viewWarning = pdfSec4.getElementsByClassName('view-warning')[0];
            if (viewWarning != null) {
                viewWarning.className = 'see-below';
                viewWarning.innerHTML = "See Below";
            }
            if (pdfSec1) {
                pdfContentPage1.appendChild(pdfSec1);
            }
            if (pdfSec2) {
                pdfContentPage1.appendChild(pdfSec2);
            }
            if (pdfSec3) {
                pdfContentPage1.appendChild(pdfSec3);
            }
            if (pdfSec4) {
                pdfContentPage1.appendChild(pdfSec4);
            }
            if (pdfSec5) {
                pdfContentPage1.appendChild(pdfSec5);
            }
            pdfContentPage1 = window.document.getElementById("rc-resultsPdf-page1");
            const pdfSec11: any = window.document.getElementsByClassName("pdf-header")[0].cloneNode(true);
            const pdfwarning: any = window.document.getElementsByClassName("download-pdf-table")[0].cloneNode(true);
            if (pdfwarning.getElementsByTagName('p').length != 0) {
                pdfwarning.style.display = "flex";
            } else {
                pdfwarning.style.display = "none !important";
            }
            const pdfSec55: any = window.document.getElementsByClassName("pdf-footer")[0].cloneNode(true);
            pdfSec11.style.display = "flex";
            pdfwarning.style.display = "block";

            pdfContentPage2.appendChild(pdfSec11);
            termColumnConatent.appendChild(pdfwarning);
            pdfContentPage2.appendChild(termColumnConatent);
            pdfContentPage2.appendChild(pdfSec55);
            pdfContentPage2 = window.document.getElementById("rc-resultsPdf-page2");

            doc.html(pdfContentPage1, {
                callback: function (doc) {
                    doc.addPage();
                    doc.html(pdfContentPage2, {
                        callback: function (doc) {
                            doc.save(DOWNLOAD_REPORT_FILENAME[calculatorType]);
                            for (let i = 1; i < pdfSec4.getElementsByClassName('info').length; i++) {
                                const child = pdfSec4.getElementsByClassName('info')[i] as HTMLCanvasElement;
                                child.style.letterSpacing = "0px";
                            }
                            if (pdfSec4.getElementsByClassName('view-warning')[0] != null) {
                                pdfSec4.getElementsByClassName('view-warning')[0].innerHTML = 'View Warning';
                            }
                            setIsDownloading(false);

                        }, y: 1300
                    });
                }, html2canvas: { scale: 1 }
            });
        } catch {
            setIsDownloading(false);
        }
        Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.LINK_TRACKING, { buttonName: "Download PDF" });
    };

    const sendAnalyticsData = (buttonNameValue) => () => {
        Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.LINK_TRACKING, { "buttonName": buttonNameValue });
    };
    return (
        <div className="resp-review">
            <div className={state.respClassName}>
                <div className={state.downAngleiconClass}>
                    {state.iconName == "Down" ? (
                        <BiChevronDown className="review-expand-icon" onClick={reviewPanelExpand} />
                    ) : (
                        <BiChevronUp className="review-expand-icon" onClick={reviewPanelExpand} />
                    )}
                </div>
                <div className="btn-container cta-mobile">
                    {DOWNLOADBUTTON_CONFIG?.map((e: any) => {
                        return (
                            e.calculatorType === calculatorType && e.enableButton === true &&
                            <div key={"btn-download-pdf"} id={"btn-download-pdf"} onClick={downloadPDF} className={"btn btn-secondary max-width mx-auto"}>{isDownloading ? <Loader /> : t(REVIEW_KEY_PANEL_ACTION.DOWNLOAD)}</div>
                        );
                    })
                    }
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{REVIEW_PANEL_ACTION.BACK}</div>
                </div>
                <div className="title">{t(LABELKEYS_RESULTS_REVIEW.HEADLINE)}</div>
                <div className="contact-specialist bor-bottom">
                    <div className="text-wrapper">
                        <div className="contact">{t(LABELKEYS_RESULTS_REVIEW.REPRESENTATOR)}</div>
                        <div className="contact">{t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.REPRESENTATOR_CONTACT, region) as string)}</div>
                    </div>
                    <div className="text-wrapper">
                        <div className="email">{t(LABELKEYS_RESULTS_REVIEW.EMAIL)}</div>
                        <div className="email"> <a href={`mailto:${t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.EMAIL_ADDRESS, region) as string)}`}>{t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.EMAIL_ADDRESS, region) as string)}</a></div>
                    </div>
                </div>
                <div className="hlinks">
                    {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ?
                        <>
                            <div className="bor-bottom"><a id={"link-supportlink"} onClick={sendAnalyticsData("Support")} href={REACT_APP_RESULTS_SUPPORT_LINK || RESULT_LINK_URL.SUPPORT} target="_blank" rel="noopener">{t(LABELKEYS_RESULTS_REVIEW.SUPPORT)}</a></div>
                            <div className="bor-bottom"><a id={"link-wheretobuy"} onClick={sendAnalyticsData("Where to Buy")} href={REACT_APP_RESULTS_WHERETOBY_LINK || RESULT_LINK_URL.WHERE_TO_BUY} target="_blank" rel="noopener">{t(LABELKEYS_RESULTS_REVIEW.BUY_OPTION)}</a></div>
                        </>
                        :
                        <>
                            <div className="bor-bottom"><a id={"link-supportlink"} onClick={sendAnalyticsData("Support")} href={REACT_APP_RESULTS_SUPPORT_LINK || RESULT_LINK_URL.SUPPORT} target="_blank" rel="noopener">{t(LABELKEYS_RESULTS_REVIEW.SUPPORT)}</a></div>
                            <div className="bor-bottom"><a id={"link-wheretobuy"} onClick={sendAnalyticsData("Where to Buy")} href={REACT_APP_RESULTS_WHERETOBY_LINK || RESULT_LINK_URL.WHERE_TO_BUY} target="_blank" rel="noopener">{t(LABELKEYS_RESULTS_REVIEW.BUY_OPTION)}</a></div>
                        </>
                    }
                </div>
            </div>
            <div className="btn-container pt-2 cta-desktop">
                {DOWNLOADBUTTON_CONFIG?.map((e: any) => {
                    return (
                        e.calculatorType === calculatorType && e.enableButton === true &&
                        <div key={"btn-download-pdf"} id={"btn-download-pdf"} onClick={downloadPDF} className={"btn btn-secondary max-width mx-auto mb-3"}>{isDownloading ? <Loader /> : t(REVIEW_KEY_PANEL_ACTION.DOWNLOAD)}</div>
                    );
                })
                }
                <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
            </div>
            <ResultsPdf />
        </div>
    );
};

export default ResultsReview;