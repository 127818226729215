import React, { useMemo, useCallback, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch, } from 'react-redux';
import "./RespReview.scss";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { RESPIRATOR_STEPS, STORE_ACTION_TYPES, LABELKEYS_CATRIDGE_REVIEW, REVIEW_KEY_PANEL_ACTION, CALCULATION_TYPES } from "../../../../helper/constants";
import NoProduct from "../../../../assets/images/cartridge/noProduct.svg";
import { FallBackProductImage } from "../../../../assets/images/cartridge/cartSelection";
import Analytics from "../../../../services/analytics";
import SummarySection from "./SummarySection";
import { scrollToParentTop } from "../../../../assets/js/utils";
const RespiratorCartridgeReview: React.FC<any> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { respiratorDescriptionValue, respiratorCartridgeSKU, productName, activeStep, respiratorImgUrl, calculatorType,currentStep, selectRespiratorCartridge } = useSelector((state: any) => state?.respirator?.calculator || {});	
    const calculator  = useSelector((state: any) => state?.respirator?.calculator || {});  	
    const {FIELD_CONFIG_RESULTS, FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE,PAGE_NAVIGATION,ANLYTICS_EVENT_TYPES, FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR } = useSelector((state: any) => state?.respirator?.config || {});	
    
    //Flag for checking if any calculation type selected 	
    const isButtonActive = useMemo(() => {	
        return ((respiratorCartridgeSKU?.length > 0));	
    }, [respiratorCartridgeSKU]);	
    
    //On continue Click - will move to next stage if region and respirator filled  	
    const onContinueButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {	
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();	
        if (isButtonActive) {	
                if (activeStep === RESPIRATOR_STEPS.CARTRIDGE_SELCTION) {	
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.WORKING_CONDITION, currentStep: RESPIRATOR_STEPS.WORKING_CONDITION } });	
                }	
                else {	
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.WORKING_CONDITION } });	
                }
                
                if(currentStep === RESPIRATOR_STEPS.CARTRIDGE_SELCTION ){
                    navigate(PAGE_NAVIGATION.ServiceLife.Working_Condition);
                    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.RESPIRATOR_SELECTION);
                }
        }	
    }, [isButtonActive, activeStep, dispatch, currentStep, navigate, PAGE_NAVIGATION.ServiceLife.Working_Condition, ANLYTICS_EVENT_TYPES]);	
    
    //On back button Click - will move to prev stage if region and respirator filled  	
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {	
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();		
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { selectRespiratorCartridge:false } });   	
    }, [dispatch]);
    
    //On AngleUp and Down Arrow Event - will expand and collapse Review Panel
    const [state, setState] = useState({
        respClassName: "resp-review-mobile",
        downAngleiconClass :"resp-acrodian",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "resp-review-mobile") {
            setState({
                respClassName: "expand-resp-panel",
                downAngleiconClass :"resp-acrodian-new",
                iconName: "Down"
            });
        }
        else {
            setState({
                respClassName: "resp-review-mobile",
                downAngleiconClass :"resp-acrodian",
                iconName: "Up"
            });
        }
    };

    

    return (
        <div className="resp-review">
            <div className={state.respClassName}>
                <div className={state.downAngleiconClass}>
                    {state.iconName == "Down" ? (
                        <BiChevronDown className="review-expand-icon" onClick={reviewPanelExpand} />
                    ) : (
                        <BiChevronUp className="review-expand-icon" onClick={reviewPanelExpand} />
                    )}
                </div>
                <div className="btn-container cta-mobile">
                    <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto" : "btn btn-disabled max-width mx-auto"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
                </div>
                <div className="title">{t(LABELKEYS_CATRIDGE_REVIEW.HEADING)}</div>
                {respiratorCartridgeSKU?.length>0 ? 
                    <div className="product-item">
                        <div className="pic"> {respiratorImgUrl?.length > 0 ?<img src={respiratorImgUrl} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=FallBackProductImage;
                              }} alt={respiratorCartridgeSKU} /> : <img src={FallBackProductImage} alt={respiratorCartridgeSKU} />}</div>
                        <div className="sub-title fw-bold">{productName}</div>
                        {respiratorCartridgeSKU?.length>0 ? <div className="sub-title fw-bold">{respiratorCartridgeSKU}</div> : "" }
                        {respiratorDescriptionValue?.length>0 ? <div className="sub-title">{respiratorDescriptionValue}</div> : "" }
                    </div>
                    :
                    <div className="product-item">
                        <img src = {NoProduct} alt="No Product Selected" />
                        <div className="sub-title fw-bold"><span>{t(LABELKEYS_CATRIDGE_REVIEW.NO_PRODUCT)}</span></div>
                    </div>
                }
                <SummarySection 
                    configType={calculatorType ===  CALCULATION_TYPES.SERVICE_LIFE
                        ?
                        (respiratorCartridgeSKU?.length <= 0 && selectRespiratorCartridge === false ? FIELD_CONFIG_RESULTS : FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR)
                        : 
                        FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE 

                    }
                    activeStep={activeStep}
                    calculator={calculator}
                />
            </div>
            <div className="btn-container pt-2 cta-desktop">
                <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto mb-3" : "btn btn-disabled max-width mx-auto mb-3"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
            </div>
        </div>
       
    );
};

export default RespiratorCartridgeReview;