/**********************************************************************************************
TermsConditionsContent.tsx - TermsConditionsContent is for binding term condition html markup
***********************************************************************************************/
import React from "react";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';
import { LABELSKEY_TERMS } from "../../../../helper/constants";
const TermsConditionsContent = (props) => {
    //Hook for traslation content                 
    const { t } = useTranslation();
    const createTermsMarkup = () => { return { __html: DOMPurify.sanitize( t(LABELSKEY_TERMS.HTML_MARKUP)?.replace("{KEY-CCNUMBER}",props?.customerServiceNo || "")) }; };
    return (
        <div dangerouslySetInnerHTML={createTermsMarkup()} />
    );};
export default TermsConditionsContent;