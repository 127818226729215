import React from 'react';
import { useSelector } from 'react-redux';
import './SearchBox.scss';

const SearchBox = (props) => {
    const { SEARCH_BOX_MAX_LENGTH } = useSelector((state: any) => state?.respirator?.config || {});
   
    return (
        <div className={`${props.showStatus?"search-block-status":"search-block"}`}>
            {props.showStatus && <p aria-hidden="true">{props.statusText}</p>}
            <div className="input-group">
                <input type="text" className="form-control" placeholder={props.placeholder} onChange={props.onChange} maxLength={SEARCH_BOX_MAX_LENGTH}/>
                <div className="input-group-append">
                    <button className={`${props.disabled?'btn btn-disabled':'btn btn-secondary'}`} type="button" onClick={props.onClick}>
                        {props.displayText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SearchBox;