import React from "react";
import { useTranslation } from "react-i18next";
import { NO_RESULT } from "../../../helper/constants";
import "./NoResults.scss";
const NoResults = () => {
    const { t } = useTranslation();
    return (
        <div className="noresults">
            <div className="subtitle">{t(NO_RESULT.HEADLINE)}</div>
            <p>{t(NO_RESULT.SUBHEADLINE)}</p>
        </div>
    );
};

export default NoResults;