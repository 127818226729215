
import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from "redux-form";
import { setupListeners } from '@reduxjs/toolkit/query';
import { configApi } from '../services/configurations';
import { productApi } from '../services/products';
import { contaminantApi } from '../services/contaminant';
import { calculationApi } from '../services/calculation';
import { respiratorsApi } from '../services/respirators';
import { contaminantWarningsApi } from '../services/contaminantWarnings';
import { STORE_ACTION_TYPES } from "../helper/constants";
import { RespiratorAppState } from "../types/allTypes";
import Analytics from "../services/analytics";
export const initialState: RespiratorAppState = {
    calculator: {
        calculatorType: "",
        termsAgreed: false,
        activeStep: "",
        currentStep: "",
        cartridgeSKU: "",
        contaminant: "",
        challengeConcentraion: "",
        region: "",
        respiratorType: "",
        productName: "",
        productDescriptionValue: "",
        productLongDescriptionValue: "",
        productImgUrl: "",
        concentrationValue: undefined,
        concentration_Value_1: 0,
        concentration_Value_2: 0,
        rateValue: 0,
        temperatureValue: -1,
        rowsPerPageValue: 10,
        humidityValue: 0,
        contaminantProductKeyValues: [],
        isVaporWarning: false,
        isVaporWarningAgreed: false,
        particlePresent: "",
        industryValue: "",
        isDisclaimerAgreed: false,
        respiratorSKU: "",
        selectRespiratorCartridge: false,
        respiratorCartridgeSKU: "",
        respiratorImgUrl: "",
        respiratorDescriptionValue: "",
        respiratorServiceLife: ""

    },
    config: undefined,
    accessToken: undefined,
    language: "en-us",
    error: { showError: false, errorMessage: undefined, errorInfo: undefined, endpointName: undefined,errorMetaInfo: undefined }

};
//get API for the end points
const getAPI = (endpointName) => {
    switch (endpointName) {
        case "getConfigurations":
            return configApi;
            break;
        case "getContaminants":
            return contaminantApi;
            break;
        case "getProducts":
            return productApi;
            break;
        case "getCalculations":
            return calculationApi;
            break;
        case "getResult":
            return calculationApi;
            break;
        case "getContaminantWarnings":
            return contaminantWarningsApi;
            break;
        case "getRespirators":
            return respiratorsApi;
            break;
        default:
            return undefined;
            break;
    }

};
export const resetQuery = () => {
    const state = store.getState();
    const error = state?.respirator?.error || {};
    const api = getAPI(error?.endpointName);
    if (api && store) {
        store.dispatch(api?.util?.resetApiState());
        store.dispatch({
            type: STORE_ACTION_TYPES.RESET_QUERY,
            payload: {},
        });
    }
};
//reducers
const respiratorReducer = (state = initialState, action: any) => {
    let { calculator, config, accessToken, error } = state;
    switch (action.type) {
        case STORE_ACTION_TYPES.CALCULATOR:
            calculator = { ...calculator, ...action.payload };
            const newState = { ...state, calculator: calculator };
            return newState as RespiratorAppState;
        case STORE_ACTION_TYPES.ERROR:
            error = action.payload;
            const newErrorState = { ...state, error: error };
            return newErrorState as RespiratorAppState;
        case STORE_ACTION_TYPES.RESET_QUERY:          
            const newQueryErrorState = { ...state, error: { ...error, showError: false, errorMessage: undefined, errorInfo: undefined, endpointName: undefined,errorMetaInfo: undefined } };
            return newQueryErrorState as RespiratorAppState;
        case STORE_ACTION_TYPES.INIT:
            config = action.payload;
            const newConfiState = { ...state, config: config };
            return newConfiState as RespiratorAppState;
        case STORE_ACTION_TYPES.CONFIG_QUERY:
        case STORE_ACTION_TYPES.CONTAMINANT_QUERY:
        case STORE_ACTION_TYPES.PRODUCT_QUERY:
        case STORE_ACTION_TYPES.CALCULATION_QUERY:
        case STORE_ACTION_TYPES.CONTAMINANTWARNING_QUERY:
        case STORE_ACTION_TYPES.RESPIRATOR_QUERY:
            const { endpointName, originalArgs } = action?.meta?.arg;           
            if (endpointName) {
                switch (endpointName) {
                    case "getConfigurations":
                        if (originalArgs?.indexOf("token?key=") > -1 && action?.payload?.isSuccess) {
                            accessToken = action?.payload?.result;
                            const newConfiState = { ...state, accessToken: accessToken };
                            return newConfiState as RespiratorAppState;
                        }
                        break;
                    case "getContaminants":
                    case "getProducts":
                    case "getCalculations":
                    case "getResult":
                    case "getContaminantWarnings":
                    case "getRespirators":
                        if (!action?.payload?.isSuccess) {                           
                            const result = action?.payload?.result;
                            Analytics.sendAnalyticsDataForError(state,result,{endpointName:action?.meta?.arg?.endpointName || undefined,url:action?.meta?.baseQueryMeta?.request?.url || "",originalArgs:JSON.stringify(action?.meta?.arg?.originalArgs || "")} || undefined,false,"");
                            const newConfiState = { ...state, error: { showError: true, errorMessage: undefined, errorInfo: result, endpointName: endpointName, errorMetaInfo: {endpointName:action?.meta?.arg?.endpointName || undefined,url:action?.meta?.baseQueryMeta?.request?.url || "",originalArgs:JSON.stringify(action?.meta?.arg?.originalArgs || "")} || undefined } };
                            return newConfiState as RespiratorAppState;

                        }
                        break;
                }
            }
            return state;
            break;

        default:
            return state;
    }
};
export const store = configureStore({
    reducer: {
        form: formReducer,
        respirator: respiratorReducer,
        [configApi.reducerPath]: configApi.reducer,
        [productApi.reducerPath]: productApi.reducer,
        [contaminantApi.reducerPath]: contaminantApi.reducer,
        [calculationApi.reducerPath]: calculationApi.reducer,
        [respiratorsApi.reducerPath]: respiratorsApi.reducer,
        [contaminantWarningsApi.reducerPath]: contaminantWarningsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(configApi.middleware).concat(productApi.middleware).concat(contaminantApi.middleware).concat(calculationApi.middleware).concat(respiratorsApi.middleware).concat(contaminantWarningsApi.middleware),
});
setupListeners(store.dispatch);