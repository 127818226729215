import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, } from 'react-redux';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./RespReview.scss";
import WarningModal from "../../../common/WarningModal/WarningModal";
import Modal from "react-bootstrap/Modal";
import { useGetCalculationsQuery } from "../../../../services/calculation";
import Analytics from "../../../../services/analytics";
import { RESPIRATOR_STEPS, STORE_ACTION_TYPES, REVIEW_KEY_PANEL_ACTION, LABELKEYS_CONDITION_REVIEW, CALCULATION_TYPES, WARNING } from "../../../../helper/constants";
import SummarySection from "./SummarySection";
import { scrollToParentTop } from "../../../../assets/js/utils";
const ConditionReview = () => {
    const { t } = useTranslation();
    const [breakthroughConcentration1, setbreakthroughConcentration1] = useState(0);
    const [breakthroughConcentration2, setbreakthroughConcentration2] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { vaporSecondaryWarnings, respiratorCartridgeSKU, selectRespiratorCartridge, calculatorType, activeStep, contaminantProductKeyValues, concentrationValue, rateValue, temperatureValue, humidityValue, currentStep } = useSelector((state: any) => state?.respirator?.calculator || {});
    const { calculator, } = useSelector((state: any) => state?.respirator || {});
    const { FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE, FIELD_CONFIG_RESULTS,PAGE_NAVIGATION,ANLYTICS_EVENT_TYPES, FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR } = useSelector((state: any) => state?.respirator?.config || {});
    
    //calculation BC10 api
    const { data: resultCalculate } = useGetCalculationsQuery({
        challengeConcentration: concentrationValue,
        exposureLimit: contaminantProductKeyValues?.exposureLimit,
    });

    //UseEffect to do calculation
    useEffect(() => {
        setbreakthroughConcentration2(resultCalculate?.result?.BC10);
        setbreakthroughConcentration1(resultCalculate?.result?.BC);
    }, [resultCalculate]);  

    //Flag for checking if any calculation done or not 
    const isButtonActive = useMemo(() => {
        return ((concentrationValue?.toString()?.trim()?.length > 0 && concentrationValue > 0 &&  Number(rateValue) > 0 && (temperatureValue && Number(temperatureValue) > -1) && Number(humidityValue) > 0));
    }, [concentrationValue, rateValue, temperatureValue, humidityValue]);

    //On continue Click - will move to next stage if region and respirator filled  
    const onContinueButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if (isButtonActive) {
            if (activeStep === RESPIRATOR_STEPS.WORKING_CONDITION) {
                dispatch({
                    type: STORE_ACTION_TYPES.CALCULATOR, payload: {
                        activeStep: RESPIRATOR_STEPS.RESULTS,
                        currentStep: RESPIRATOR_STEPS.RESULTS,
                        concentration_Value_1: getFormattedNo(breakthroughConcentration1),
                        concentration_Value_2: getFormattedNo(breakthroughConcentration2)
                    }
                });
            }
            else {
                dispatch({
                    type: STORE_ACTION_TYPES.CALCULATOR, payload: {
                        currentStep: RESPIRATOR_STEPS.RESULTS,
                        concentration_Value_1: getFormattedNo(breakthroughConcentration1),
                        concentration_Value_2: getFormattedNo(breakthroughConcentration2)
                    }
                });
            }

            if (currentStep === RESPIRATOR_STEPS.WORKING_CONDITION) {
                navigate(PAGE_NAVIGATION.ServiceLife.Results);
                Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.WORKING_CONDITION);
            }
        }

    }, [isButtonActive, activeStep, dispatch, breakthroughConcentration1, breakthroughConcentration2, currentStep, PAGE_NAVIGATION.ServiceLife.Results,ANLYTICS_EVENT_TYPES, navigate]);

    //On back button Click - will move to prev stage if region and respirator filled  
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION, selectRespiratorCartridge: false } });
        navigate(PAGE_NAVIGATION.ServiceLife.Product_Selection);
    }, [PAGE_NAVIGATION.ServiceLife.Product_Selection, dispatch, navigate]);

    //On AngleUp and Down Arrow Event - will expand and collapse Review Panel
    const [state, setState] = useState({
        respClassName: "resp-review-mobile",
        downAngleiconClass: "resp-acrodian",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "resp-review-mobile") {
            setState({
                respClassName: "expand-resp-panel",
                downAngleiconClass: "resp-acrodian-new",
                iconName: "Down"
            });
        }
        else {
            setState({
                respClassName: "resp-review-mobile",
                downAngleiconClass: "resp-acrodian",
                iconName: "Up"
            });
        }
    };
    //Modal Functionality:
    const [show, setShow] = useState(false);

    const showingWarningModal = useCallback(() => {
        if (isButtonActive) {
            setShow(true);
        }
    }, [isButtonActive]);

    const closeWarningModal = useCallback(() => {
        if (isButtonActive) {
            setShow(false);
        }
    }, [isButtonActive]);
    //Modal Functionality Ends

    const getFormattedNo=(resultValue)  =>
    {
    const numCollection =resultValue?.toString().split('.');
    const deciPoints= numCollection?.length>0 ?numCollection [1]:0;
    if(deciPoints?.length>0)
    {
      if(Number(deciPoints)>0)
     {
      return resultValue; 
     }
    else
    {
    return numCollection[0]; 
    }
    
    }
    else
    {
    return resultValue; 
    }
    };

    return (
        <div className="resp-review">
            <div className={state.respClassName}>
                <div className={state.downAngleiconClass}>
                    {state.iconName == "Down" ? (
                        <BiChevronDown className="review-expand-icon" onClick={reviewPanelExpand} />
                    ) : (
                        <BiChevronUp className="review-expand-icon" onClick={reviewPanelExpand} />
                    )}
                </div>

                <div className="btn-container cta-mobile">
                    {
                    contaminantProductKeyValues?.idlh && contaminantProductKeyValues?.idlh <= concentrationValue 
                    ?
                    <div onClick={showingWarningModal} className={isButtonActive ? "btn btn-secondary max-width mx-auto" : "btn btn-disabled max-width mx-auto"}>
                    {t(REVIEW_KEY_PANEL_ACTION.SUBMIT)}
                    </div>
                    :
                    <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto" : "btn btn-disabled max-width mx-auto"}>
                    {t(REVIEW_KEY_PANEL_ACTION.SUBMIT)}
                    </div>
                    }
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
                </div>
                <div className="title">{t(LABELKEYS_CONDITION_REVIEW.HEADING)}</div>
                <div className="conditions bor-bottom">
                    <div className="text-wrapper">
                        <div className="info"><span>{t(LABELKEYS_CONDITION_REVIEW.CONCENTRATION_NAME_RESULT)}</span>({t(LABELKEYS_CONDITION_REVIEW.CHALLENGE_CONCENTRATION_SPAN_1)})</div>
                        <div className="info">{getFormattedNo(breakthroughConcentration1) || "-"}</div>
                    </div>
                    <div className="text-wrapper">
                        <div className="info"><span>{t(LABELKEYS_CONDITION_REVIEW.CONCENTRATION_NAME_RESULT)}</span>({t(LABELKEYS_CONDITION_REVIEW.CHALLENGE_CONCENTRATION_SPAN_2)})</div>
                        <div className="info">{getFormattedNo(breakthroughConcentration2) || "-"}</div>
                    </div>
                </div>
                <SummarySection 
                    configType={calculatorType ===  CALCULATION_TYPES.SERVICE_LIFE
                        ?
                        (respiratorCartridgeSKU?.length <= 0 && selectRespiratorCartridge === false ? FIELD_CONFIG_RESULTS : FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR)
                        : 
                        FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE
                    }
                    activeStep={activeStep}
                    calculator={calculator}
                />
            </div>
            <div className="btn-container pt-2 cta-desktop">
                {contaminantProductKeyValues?.idlh && contaminantProductKeyValues?.idlh <= concentrationValue  
                ?
                <div onClick={showingWarningModal} className={isButtonActive ? "btn btn-secondary max-width mx-auto mb-3" : "btn btn-disabled max-width mx-auto mb-3"}>
                {t(REVIEW_KEY_PANEL_ACTION.SUBMIT)}
                </div>
                :
                <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto mb-3" : "btn btn-disabled max-width mx-auto mb-3"}>
                {t(REVIEW_KEY_PANEL_ACTION.SUBMIT)}
            </div>
            }
                <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
            </div>
            <Modal show={show}>
                <WarningModal showWarningIcon isHtmlMessage
                    messageHeader={t(WARNING.HEADLINE)}
                    message={vaporSecondaryWarnings?.WAR_IDLH || ""}
                    onClickYes={closeWarningModal}
                    labelYes={t(WARNING.OK)}
                    labelRed='true'
                />
            </Modal>
        </div>
    );
};

export default ConditionReview;