/**********************************************************************************************
MainContent.tsx - Wrapper of the respirator calculator application
***********************************************************************************************/
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useGetConfigurationsQuery } from '../../services/configurations';
import Loader from "../common/Loader/Loader";
import CalculatorHome from "../dashboard/CalculatorHome";
import { LANG_API_PATHS, ERROR_MESSAGES } from "../../helper/constants";
const MainContent = () => {
  const { i18n } = useTranslation();
  const language = useSelector((state: any) => state?.respirator?.language || "en-us");
  const config = useSelector((state: any) => state?.respirator?.config || undefined);
  const { data, error, isLoading } = useGetConfigurationsQuery(LANG_API_PATHS(language));
  //setting lanaguage traslation files from node service for lables
  useEffect(() => {
    if (data?.isSuccess) {     
      const resources = data?.result;
      i18n.init({
        resources,
        fallbackLng: "enUS",
        debug: true,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
    }
    else if (!data?.isSuccess && error) {
      throw (ERROR_MESSAGES.LANG_FILE_NOT_FOUND);
    }
  }, [data, error, i18n, language]);
  return (
    <div className="mainContent" role="main">
      {isLoading || !config ? <Loader /> :
        <CalculatorHome />}

    </div>
  );
};
export default MainContent;
