/**********************************************************************************************
RespiratorSteps.tsx - Respiratror is for showing steps based on calculation type
***********************************************************************************************/
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./RespiratorSteps.scss";
//import Breadcrumb from "../Breadcrumb/Breadcrumb";
import CartSelection from "./CartSelection";
import ProgressBar from '../../../common/ProgressBar/ProgressStepper';
//import StepSelection from "./StepSelection";
import Terms from "../Results/Terms/Terms";
import { RESPIRATOR_STEPS, ARIA_LABEL, LABELKEYS_RESPIRATOR_STEPS,LABELKEYS_RESULTS_REVIEW_REGION_ITEMS,LABELKEYS_RESULTS_REVIEW } from "../../../../helper/constants";
import StepSelection from "./StepSelection";
const RespiratorSteps = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { calculatorType, activeStep, currentStep, termsAgreed,region  } = useSelector((state: any) => state?.respirator?.calculator || {});
    const { PROGRESSBAR_STEPS_VALUE_COLL,PAGE_NAVIGATION,CALCULATION_TYPES_VALUES } = useSelector((state: any) => state?.respirator?.config || {});
    //Redireting to home if user accessing the page directly
    useEffect(() => {
        if (!termsAgreed || (CALCULATION_TYPES_VALUES?.indexOf(calculatorType) < 0)) {
            navigate(PAGE_NAVIGATION.HOME);
        }
    }, [termsAgreed, calculatorType,PAGE_NAVIGATION.HOME,CALCULATION_TYPES_VALUES,navigate]);
    return (
        <div className="main-wrapper" role="region" aria-label={t(ARIA_LABEL.RESPIRATOR_CALCULTAION)}>
               {/* <Breadcrumb />*/}
            <div className="main-heading">{t(LABELKEYS_RESPIRATOR_STEPS.MAIN_HEADING)}</div>
            <ProgressBar step={PROGRESSBAR_STEPS_VALUE_COLL[calculatorType]} completed={activeStep} />
            <div className="row pt-4">
                <div className="col-md-9 col-sm-12">
                    <StepSelection currentStep={currentStep} calculatorType={calculatorType} />
                    {/* <Outlet/> */}
                </div>
                <div className="col-md-3 col-sm-12">
                    <CartSelection currentStep={currentStep} calculatorType={calculatorType} />
                </div>
                {currentStep == RESPIRATOR_STEPS.RESULTS && <Terms customerServiceNo={(t(LABELKEYS_RESULTS_REVIEW_REGION_ITEMS(LABELKEYS_RESULTS_REVIEW.REPRESENTATOR_CONTACT,region) as string) || undefined)} />}
            </div>
        </div>
    );
};
export default RespiratorSteps;