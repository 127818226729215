/**********************************************************************************************
CartSelection.tsx - To manage cart components
***********************************************************************************************/
import React from "react";
import RespReview from "../Reviews/RespReview";
import CartridgeReview from "../Reviews/CartridgeReview";
import ContainmentReview from "../Reviews/ContainmentReview";
import ConditionReview from "../Reviews/ConditionReview";
import ResultsReview from "../Reviews/ResultsReview";
import { RESPIRATOR_STEPS } from "../../../../helper/constants";
import { useSelector } from "react-redux";
import RespiratorCartridgeReview from "../Reviews/RespiratorCartridgeReview";
const CartSelection: React.FC<{ currentStep: string, calculatorType: string }> = ({ currentStep }) => { 
    const {selectRespiratorCartridge } = useSelector((state: any) => state?.respirator?.calculator || {});  	
    return (<>
        {(() => {
            switch (currentStep) {
                case RESPIRATOR_STEPS.RESPIRATOR_SELECTION:
                    return <RespReview />;
                    break;
                case RESPIRATOR_STEPS.CARTRIDGE_SELCTION:
                    if (selectRespiratorCartridge === false)
                    {
                        return <CartridgeReview/>;
                    }
                    else
                    {
                        return <RespiratorCartridgeReview />;
                    }               
                    break;
                case RESPIRATOR_STEPS.CONTAINMENT_SELECTION:
                    return <ContainmentReview />;
                    break;
                case RESPIRATOR_STEPS.WORKING_CONDITION:
                    return <ConditionReview />;
                    break;
                case RESPIRATOR_STEPS.RESULTS:
                    return <ResultsReview />;
                    break;
            }
        })()}</>
    );
};
export default CartSelection;