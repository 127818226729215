/* eslint-disable react/react-in-jsx-scope */
import "./ErrorHandling.scss";
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import WarningModal from '../WarningModal/WarningModal';
import { ERROR_MESSAGES, STORE_ACTION_TYPES, WARNING } from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { initialState, resetQuery } from "../../../store/respiratorStore";
import Analytics from "../../../services/analytics";
const ErrorHandling = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { calculatorType, activeStep } = useSelector((state: any) => state?.respirator?.calculator || {});
    const { error } = useSelector((state: any) => state?.respirator || {});
    const { respirator } = useSelector((state: any) => state || {});
    const { ERROR_HANDLING_COLLECTION, PAGE_NAVIGATION, ERROR_ACTION_TYPES } = useSelector((state: any) => state?.respirator?.config || {});
    //Modal Functionality:
    const [show, setShow] = useState(true);
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        setShow(false);
        if (activeStep === ERROR_HANDLING_COLLECTION[calculatorType][activeStep]?.fieldKey) {
            Analytics.sendAnalyticsDataForError(respirator, error?.errorInfo || "", error?.errorMetaInfo || undefined, true, ERROR_ACTION_TYPES?.BACK || "back");
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: ERROR_HANDLING_COLLECTION[calculatorType][activeStep].fieldValue, activeStep: ERROR_HANDLING_COLLECTION[calculatorType][activeStep].fieldValue } });
            navigate(ERROR_HANDLING_COLLECTION[calculatorType][activeStep]?.navigationUrl || PAGE_NAVIGATION.HOME);
            resetQuery();
        }
    }, [ERROR_HANDLING_COLLECTION, calculatorType, activeStep, PAGE_NAVIGATION, navigate, dispatch, error, respirator, ERROR_ACTION_TYPES]);

    const onResetButtonClick = useCallback((e) => {
        e.preventDefault();
        setShow(false);
        navigate(PAGE_NAVIGATION.HOME);
        Analytics.sendAnalyticsDataForError(respirator, error?.errorInfo || "", error?.errorMetaInfo || undefined, true, ERROR_ACTION_TYPES?.RESET || "reset");
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { ...initialState.calculator } });
        resetQuery();
    }, [PAGE_NAVIGATION.HOME, dispatch, navigate, error, respirator, ERROR_ACTION_TYPES]);

    //Modal Functionality Ends
    return (
        <div className="errorhandling">
            <Modal show={show}>
                <WarningModal showWarningIcon
                    messageHeader={t(WARNING.ERROR)}
                    message={t(ERROR_MESSAGES.ERROR_HANDLING_MESSAGE)}
                    onClickNo={onBackButtonClick}
                    onClickYes={onResetButtonClick}
                    labelNo={t(WARNING.BACK)}
                    labelYes={t(WARNING.RESTART)}
                />
            </Modal>
        </div>
    );
};

export default ErrorHandling;