import React from "react";
import "./Breadcrumb.scss";
import { useTranslation } from "react-i18next";
import { ARIA_LABEL, LABELKEYS_BREAD_CRUMB} from "../../../../helper/constants";
const Breadcrumb = () => {
        const { t } = useTranslation();
    return (
        <nav className="breadcrumb-list" aria-label={t(ARIA_LABEL.BREADCRUMBS)}>
            <ul>
                <li><a href="/">{t(LABELKEYS_BREAD_CRUMB.HOME)}</a></li>
                <li><a href="/">{t(LABELKEYS_BREAD_CRUMB.LANDING)}</a></li>
                <li className="active">{t(LABELKEYS_BREAD_CRUMB.RESPIRATOR)}</li>
            </ul>
        </nav>
    );
};

export default Breadcrumb;