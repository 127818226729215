import React, { useMemo, useCallback, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch, } from 'react-redux';
import "./RespReview.scss";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { RESPIRATOR_STEPS, STORE_ACTION_TYPES, LABELKEYS_CATRIDGE_REVIEW, REVIEW_KEY_PANEL_ACTION, CALCULATION_TYPES, WARNING } from "../../../../helper/constants";
import NoProduct from "../../../../assets/images/cartridge/noProduct.svg";
import { FallBackProductImage } from "../../../../assets/images/cartridge/cartSelection";
import Analytics from "../../../../services/analytics";
import SummarySection from "./SummarySection";
import Modal from "react-bootstrap/Modal";
import WarningModal from "../../../common/WarningModal/WarningModal";
import { scrollToParentTop } from "../../../../assets/js/utils";
const CartridgeReview: React.FC<any> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { productDescriptionValue, respiratorCartridgeSKU, cartridgeSKU, productName, activeStep, productImgUrl, calculatorType,currentStep, selectRespiratorCartridge } = useSelector((state: any) => state?.respirator?.calculator || {});	
    const calculator  = useSelector((state: any) => state?.respirator?.calculator || {});  	
    const {FIELD_CONFIG_RESULTS, FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE,PAGE_NAVIGATION,ANLYTICS_EVENT_TYPES, FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR,ANLYTICS_FIELDS_DEFAULT_VALUES } = useSelector((state: any) => state?.respirator?.config || {});	
    const [show, setShow] = useState(false);
    //Flag for checking if any calculation type selected 	
    const isButtonActive = useMemo(() => {	
        return ((cartridgeSKU?.length > 0));	
    }, [cartridgeSKU]);	
    
    //On continue Click - will move to next stage if region and respirator filled  	
    const onContinueButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
        window.scrollTo(0, 0);	
        scrollToParentTop();
        e.preventDefault();	
        if (isButtonActive) {
            if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)	
            {	
                setShow(true);
            }	
            else if(calculatorType === CALCULATION_TYPES.VAPORS)	
            {	
                if (activeStep === RESPIRATOR_STEPS.CARTRIDGE_SELCTION) {	
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.RESULTS, currentStep: RESPIRATOR_STEPS.RESULTS } });	
                }	
                else {	
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.RESULTS } });	
                }
                
                if(currentStep === RESPIRATOR_STEPS.CARTRIDGE_SELCTION ){
                    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.CARTRIDGE_SELECTION);
                }
            }	
        }	
    }, [isButtonActive, calculatorType, activeStep, dispatch, currentStep, ANLYTICS_EVENT_TYPES]);	
    
    //On back button Click - will move to prev stage if region and respirator filled  	
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();	
        if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)	
        {	
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.CONTAINMENT_SELECTION, showError:false } });
            navigate(PAGE_NAVIGATION.ServiceLife.Contaminant_Selection);	
        }	
        else if(calculatorType === CALCULATION_TYPES.VAPORS)	
        {	
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.RESPIRATOR_SELECTION, showError:false } });
            navigate(PAGE_NAVIGATION.Vapors.Respirator_Selection);	
        }	
        	
    }, [calculatorType, dispatch,navigate,PAGE_NAVIGATION.ServiceLife.Contaminant_Selection,PAGE_NAVIGATION.Vapors.Respirator_Selection]);
    
    //On AngleUp and Down Arrow Event - will expand and collapse Review Panel
    const [state, setState] = useState({
        respClassName: "resp-review-mobile",
        downAngleiconClass :"resp-acrodian",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "resp-review-mobile") {
            setState({
                respClassName: "expand-resp-panel",
                downAngleiconClass :"resp-acrodian-new",
                iconName: "Down"
            });
        }
        else {
            setState({
                respClassName: "resp-review-mobile",
                downAngleiconClass :"resp-acrodian",
                iconName: "Up"
            });
        }
    };

    
//Modal Functionality:
const showRespiratorSuggestedScreen = useCallback(() => {
    dispatch({
        type: STORE_ACTION_TYPES.CALCULATOR,
        payload: {selectRespiratorCartridge : true,selectRespiratorOption:ANLYTICS_FIELDS_DEFAULT_VALUES?.YES || "Yes"},
    });
    setShow(false);
    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.RESPIRATOR_SELECTION_OPTION);
}, [dispatch,ANLYTICS_FIELDS_DEFAULT_VALUES,ANLYTICS_EVENT_TYPES]);

const closeRespiratorSuggestedModel = useCallback(()=>{
  if (activeStep === RESPIRATOR_STEPS.CARTRIDGE_SELCTION) {	
    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.WORKING_CONDITION, currentStep: RESPIRATOR_STEPS.WORKING_CONDITION } });	
    }	
    else {	
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.WORKING_CONDITION } });	
    }

    if(currentStep === RESPIRATOR_STEPS.CARTRIDGE_SELCTION ){
        navigate(PAGE_NAVIGATION.ServiceLife.Working_Condition);
        Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.CARTRIDGE_SELECTION);
    }
  dispatch({
    type: STORE_ACTION_TYPES.CALCULATOR,
    payload: {respiratorCartridgeSKU : ""},
});
  setShow(false);
},[ANLYTICS_EVENT_TYPES?.CARTRIDGE_SELECTION, PAGE_NAVIGATION.ServiceLife.Working_Condition, activeStep, currentStep, dispatch, navigate]);
//Modal Functionality Ends
    return (
        <div className="resp-review">
            <div className={state.respClassName}>
                <div className={state.downAngleiconClass}>
                    {state.iconName == "Down" ? (
                        <BiChevronDown className="review-expand-icon" onClick={reviewPanelExpand} />
                    ) : (
                        <BiChevronUp className="review-expand-icon" onClick={reviewPanelExpand} />
                    )}
                </div>
                <div className="btn-container cta-mobile">
                    <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto" : "btn btn-disabled max-width mx-auto"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
                </div>
                <div className="title">{t(LABELKEYS_CATRIDGE_REVIEW.HEADING)}</div>
                {cartridgeSKU?.length>0 ? 
                    <div className="product-item">
                        <div className="pic"> {productImgUrl?.length > 0 ?<img src={productImgUrl} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=FallBackProductImage;
                              }} alt={cartridgeSKU} /> : <img src={FallBackProductImage} alt={cartridgeSKU} />}</div>
                        <div className="sub-title fw-bold">{productName}</div>
                        {cartridgeSKU?.length>0 ? <div className="sub-title fw-bold">{cartridgeSKU}</div> : "" }
                        {productDescriptionValue?.length>0 ? <div className="sub-title">{productDescriptionValue}</div> : "" }
                    </div>
                    :
                    <div className="product-item">
                        <img src = {NoProduct} alt="No Product Selected" />
                        <div className="sub-title fw-bold"><span>{t(LABELKEYS_CATRIDGE_REVIEW.NO_PRODUCT)}</span></div>
                    </div>
                }
                <SummarySection 
                    configType={calculatorType ===  CALCULATION_TYPES.SERVICE_LIFE
                        ?
                        (respiratorCartridgeSKU?.length <= 0 && (selectRespiratorCartridge === false || respiratorCartridgeSKU?.length<=0) ? FIELD_CONFIG_RESULTS : FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR)
                        : 
                        FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE 

                    }
                    activeStep={activeStep}
                    calculator={calculator}
                />
            </div>
            <div className="btn-container pt-2 cta-desktop">
                <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto mb-3" : "btn btn-disabled max-width mx-auto mb-3"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
            </div>
            {calculatorType === CALCULATION_TYPES.SERVICE_LIFE && selectRespiratorCartridge === false && 
            <Modal show={show}>
                <WarningModal 
                    messageHeader={t(WARNING.SUGGESTED)}
                    message={t(WARNING.SUGGESTED_RESPIRATOR_DESCRIPTION)}
                    onClickYes={showRespiratorSuggestedScreen}
                    onClickNo={closeRespiratorSuggestedModel}
                    labelYes={t(WARNING.YES)}
                    labelNo={t(WARNING.NO)}
                />
            </Modal>}
        </div>
    );
};

export default CartridgeReview;