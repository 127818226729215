/**********************************************************************************************
RespReview.tsx - Revirew panel for respirator selction
***********************************************************************************************/
import React, { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from "react-router-dom";
import "./RespReview.scss";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import Analytics from "../../../../services/analytics";
import { Default_USARegion, ImgReusable, ImgParr, ImgDefaultRespirator, ImgDefaultRegion, ImgDefaultEMEA } from "../../../../assets/images/respselection/respSelection";
import { RESPIRATOR_STEPS, STORE_ACTION_TYPES, REVIEW_KEY_PANEL_WARNINGS, LABELKEYS_RESPIRATOR_REVIEW, REVIEW_KEY_PANEL_ACTION, CALCULATION_TYPES, PARTICLE_OPTION_VALUES } from "../../../../helper/constants";
import SummarySection from "./SummarySection";
import { scrollToParentTop } from "../../../../assets/js/utils";
const RespReview = () => {
    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { respiratorCartridgeSKU, selectRespiratorCartridge, region, respiratorType, activeStep, calculatorType, particlePresent,currentStep  } = useSelector((state: any) => state?.respirator?.calculator || {});
    const calculator  = useSelector((state: any) => state?.respirator?.calculator || {});  
    const {FIELD_CONFIG_RESULTS,ANLYTICS_EVENT_TYPES, FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE,PAGE_NAVIGATION, FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR,RESPIRATOR_REGION,RESPIRATORS_TYPES} = useSelector((state: any) => state?.respirator?.config || {});
    
    //Flag for checking if any calculation type selected 
    const isButtonActive = useMemo(() => {
        if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)
        {
            return ((region === RESPIRATOR_REGION.USA || region === RESPIRATOR_REGION.EMEA_APAC) && (respiratorType === RESPIRATORS_TYPES.APR || respiratorType === RESPIRATORS_TYPES.PAPR || respiratorType === RESPIRATORS_TYPES.REUSABLE));
        }
        else if (calculatorType === CALCULATION_TYPES.VAPORS)
        {
            return ((region === RESPIRATOR_REGION.USA || region === RESPIRATOR_REGION.EMEA_APAC) && (respiratorType === RESPIRATORS_TYPES.APR || respiratorType === RESPIRATORS_TYPES.PAPR || respiratorType === RESPIRATORS_TYPES.REUSABLE) && (particlePresent === PARTICLE_OPTION_VALUES.YES || particlePresent === PARTICLE_OPTION_VALUES.NO));
        }
        }, [calculatorType, particlePresent, region, respiratorType,RESPIRATORS_TYPES.APR,RESPIRATORS_TYPES.PAPR,RESPIRATOR_REGION.USA,RESPIRATORS_TYPES.REUSABLE,RESPIRATOR_REGION.EMEA_APAC]);

    //On continue Click - will move to next stage if region and respirator filled  
    const onContinueButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if (isButtonActive) {
            if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)
            {
                if (activeStep === RESPIRATOR_STEPS.RESPIRATOR_SELECTION) {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.CONTAINMENT_SELECTION, currentStep: RESPIRATOR_STEPS.CONTAINMENT_SELECTION } });
                    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.RESPIRATOR_TYPE_SELECTION);
                }
                else {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.CONTAINMENT_SELECTION } });
                    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.RESPIRATOR_TYPE_SELECTION);
                }

                if(currentStep === RESPIRATOR_STEPS.RESPIRATOR_SELECTION ){
                    navigate(PAGE_NAVIGATION.ServiceLife.Contaminant_Selection);
                }
            }
            else if(calculatorType === CALCULATION_TYPES.VAPORS)
            {
                if (activeStep === RESPIRATOR_STEPS.RESPIRATOR_SELECTION) {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION, currentStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION } });
                }
                else {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION } });
                }

                if(currentStep === RESPIRATOR_STEPS.RESPIRATOR_SELECTION ){
                    navigate(PAGE_NAVIGATION.Vapors.Product_Selection);
                }
            }
        }
    }, [isButtonActive, calculatorType, activeStep,ANLYTICS_EVENT_TYPES,PAGE_NAVIGATION.ServiceLife.Contaminant_Selection,PAGE_NAVIGATION.Vapors.Product_Selection,dispatch,currentStep,navigate]);

    //On back button Click - will move to prev stage if region and respirator filled  
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)
            {
                navigate(PAGE_NAVIGATION[calculatorType].Terms_Conditions);
            }
            else if(calculatorType === CALCULATION_TYPES.VAPORS)
            {
                dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.CONTAINMENT_SELECTION, showError: false } });
                navigate(PAGE_NAVIGATION.Vapors.Contaminant_Selection);
            }
        
    }, [calculatorType,PAGE_NAVIGATION, dispatch, navigate]);

    //function to switch between images for region 
    const regionImageSwitch = useMemo(() => {
        switch (region) {
            case RESPIRATOR_REGION.USA:
                return Default_USARegion;
                break;
            case RESPIRATOR_REGION.EMEA_APAC:
                return ImgDefaultEMEA;
                break;
        }
    }, [region,RESPIRATOR_REGION]);

    //function to switch between images for respirator type 
    const typeImageSwitch = useMemo(() => {
        switch (respiratorType) {
            case RESPIRATORS_TYPES.REUSABLE:
                return ImgReusable;
                break;
            case RESPIRATORS_TYPES.APR:
                return ImgReusable;
                break;
            case RESPIRATORS_TYPES.PAPR:
                return ImgParr;
                break;
        }
    }, [respiratorType,RESPIRATORS_TYPES.REUSABLE,RESPIRATORS_TYPES.APR,RESPIRATORS_TYPES.PAPR]);

    //On AngleUp and Down Arrow Event - will expand and collapse Review Panel
    const [state, setState] = useState({
        respClassName: "resp-review-mobile",
        downAngleiconClass :"resp-acrodian",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "resp-review-mobile") {
            setState({
                respClassName: "expand-resp-panel",
                downAngleiconClass :"resp-acrodian-new",
                iconName: "Down"
            });
        }
        else {
            setState({
                respClassName: "resp-review-mobile",
                downAngleiconClass :"resp-acrodian",
                iconName: "Up"
            });
        }
    };
    //RespReview return statement starts
    return (
        <div className="resp-review">
            <div className={state.respClassName}>
                <div className={state.downAngleiconClass}>
                    {state.iconName == "Down" ? (
                        <BiChevronDown className="review-expand-icon" onClick={reviewPanelExpand} />
                    ) : (
                        <BiChevronUp className="review-expand-icon" onClick={reviewPanelExpand} />
                    )}
                </div>
                <div className="btn-container cta-mobile">
                    <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto" : "btn btn-disabled max-width mx-auto"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
                </div>
                <div className="title">{t(LABELKEYS_RESPIRATOR_REVIEW.HEADING)}</div>
                <div className="images-list">
                    <ul>
                        {region?.length > 0 ? 
                            <li className="d-inline-block">
                                <img src={regionImageSwitch} alt={region} />
                                <p className="sub-title">{region}</p>
                            </li>
                            : 
                            <li className="d-inline-block">
                                <img src={ImgDefaultRegion} alt="No Region" /> 
                                <p className="no-data">{t(REVIEW_KEY_PANEL_WARNINGS.NO_REGION)}</p> 
                            </li>
                        }
                        {respiratorType?.length > 0 ? 
                            <li className="d-inline-block pic_respiratorType">
                                <img src={typeImageSwitch} alt={region} />
                                <p className="sub-title">{respiratorType}</p>
                            </li>
                            : 
                            <li className="d-inline-block">
                                <img src={ImgDefaultRespirator} alt="No Respirator Type" /> 
                                <p className="no-data">{t(REVIEW_KEY_PANEL_WARNINGS.NO_RESPIRATOR)}</p> 
                            </li>
                        }
                    </ul>
                </div>
                <SummarySection 
                    configType={calculatorType ===  CALCULATION_TYPES.SERVICE_LIFE
                        ?
                        (respiratorCartridgeSKU?.length <= 0 && selectRespiratorCartridge === false ? FIELD_CONFIG_RESULTS : FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR)
                        :  
                        FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE
                    }
                    activeStep={activeStep}
                    calculator={calculator}
                />
            </div>
            <div className="btn-container pt-2 cta-desktop">
                <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto mb-3" : "btn btn-disabled max-width mx-auto mb-3"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
            </div>
        </div>
    );
};

export default RespReview;

