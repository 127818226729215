let globalFrameHeight=0;
// Global addAnalytics function for adding analytic data
/* eslint-disable */
export function addAnalytics (data) {
  if(data)
  {
    const jasonData=JSON.stringify({
      key: 'analytics',
      value: data
  }); 
  //pushing analytics data to parent
    parent?.window?.postMessage(jasonData, "*");
  }
}
//number to plainString
function toPlainString (num) {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (
    a,
    b,
    c,
    d,
    e
  ) {
    return e < 0
      ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
      : b + c + d + Array(e - d.length + 1).join(0)
  })
}
//Function to sent updated height to iframe 
/* eslint-disable */
export function resizeParent (extraHeight=0) {  
  try{
  const elmBody = window.document?.body;
  const elmHtml = window.document?.documentElement;
  if (elmBody && elmHtml) {  
      setTimeout(function() { 
  let frameHeight=elmHtml.offsetHeight+Math.floor(elmHtml.scrollTop);
 if(frameHeight !=globalFrameHeight)
  {
    if(extraHeight>0)
    {
      globalFrameHeight=frameHeight+extraHeight;
    }
    else{
   globalFrameHeight=frameHeight;
    }
   parent.window.postMessage(globalFrameHeight, "*");
  }
  }, 100);  
  }
}catch{}
}
//scroll to top
export function scrollToParentTop()
{
   parent?.window?.postMessage(JSON.stringify({key: 'scrolltop',value: ""}), "*");
}
