/**********************************************************************************************
CalculatorHome.tsx - Home page of the respirator calculator application & managing redirections
***********************************************************************************************/
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CalculatorSelection from "./pages/CalculatorSelection/CalculatorSelection";
import CartridgeSelection from './pages/CartridgeSelection/CartridgeSelection';
import ContaminantSelection from './pages/ContaminantSelection/ContaminantSelection';
import RespiratorSelection from './pages/RespiratorSelection/RespiratorSelection';
import RespiratorSteps from "./pages/RespiratorSteps/RespiratorSteps";
import StepsTest from "./pages/RespiratorSteps/StepsTest";
import Results from './pages/Results/Results';
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import WorkingConditions from './pages/WorkingConditions/WorkingConditions';
const CalculatorHome = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<CalculatorSelection />} />
                <Route path="service-life/terms-conditions" element={<TermsConditions />} />
                <Route path="select-cartridge/terms-conditions" element={<TermsConditions />} />
                <Route path="service-life/*" element={<RespiratorSteps />} >
                    <Route path="respirator-selection" element={<RespiratorSelection />} />
                    <Route path="contaminant-selection" element={<ContaminantSelection />} />
                    <Route path="product-selection" element={<CartridgeSelection />} />
                    <Route path="working-condition" element={<WorkingConditions />} />
                    <Route path="results" element={<Results />} />
                </Route>
                <Route path="select-cartridge/*" element={<RespiratorSteps />} >                   
                    <Route path="contaminant-selection" element={<ContaminantSelection />} />
                    <Route path="respirator-selection" element={<RespiratorSelection />} />
                    <Route path="product-selection" element={<CartridgeSelection />} />
                    <Route path="results" element={<Results />} />
                </Route>
                <Route path="test" element={<StepsTest />} />
            </Routes>
        </BrowserRouter>

    );
};
export default CalculatorHome;
