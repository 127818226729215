/**********************************************************************************************
CartridgeSelection.tsx - User can select product from the given list
***********************************************************************************************/
import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./CartridgeSelection.scss";
import "../Reviews/RespReview.scss";
import Loader from "../../../common/Loader/Loader";
import { ProductSelection } from "../../../../types/allTypes";
import {STORE_ACTION_TYPES, LABELKEYS_CATRIDGE_STEP, REGEX_VALIDATION } from "../../../../helper/constants";
import NoResults from "../../../common/NoResults/NoResults";
import { useGetProductsQuery } from "../../../../services/products";
import SearchBox from "../../../common/SearchBox/SearchBox";
import { FallBackProductImage } from "../../../../assets/images/cartridge/cartSelection";
import ErrorHandling from "../../../common/ErrorHandling/ErrorHandling";
import { resizeParent } from "../../../../assets/js/utils";

const CartridgeSelection: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { calculator } = useSelector((state: any) => state?.respirator || {});
  const { cartridgeSKU } = useSelector((state: any) => state?.respirator?.calculator || {});
  const { showError } = useSelector((state: any) => state?.respirator?.error || {});
  const [productList, setProductList] = useState<any>([]);
  const [totalRecord, setTotalRecord] = useState(0); 
  const [searchTermFind, setsearchTerm] = useState("");
  const [visibleProductList, setVisible] = useState(0);
  const [defaultsearch, setSearch] = useState(false);
  const {CONFIG_DEFAULT_PRODUCT_LIST_ROWS, CONFIG_MIN_LETTERS_FOR_SEARCH_CARTRIDGE ,RESPIRATOR_REGION,CALCULATION_TYPES} = useSelector((state: any) => state?.respirator?.config || {});
  
  /*------Cartridge product selection inital state-----*/
  const [productState, setProductState] = useState<ProductSelection>({
    pageNumber: 1,
    rows: CONFIG_DEFAULT_PRODUCT_LIST_ROWS,
    sortBy: "sku",
    sortOrder: "asc",
    searchText: searchTermFind,
    searchField: "",
    region: calculator?.region === RESPIRATOR_REGION?.EMEA_APAC?"EMEA":calculator?.region,
    respiratorType: calculator?.respiratorType,
    particlePresent: CALCULATION_TYPES?.VAPORS === calculator?.calculatorType ? calculator?.particlePresent:""

  });
  /*------Cartridge product selection inital state ends-----*/

  const { pageNumber, rows, sortBy, sortOrder, searchField, searchText } = productState;

  /*------Product Query -----*/
  const { data, isLoading } = useGetProductsQuery({
    pageNumber: pageNumber,
    searchText: defaultsearch === true ? searchText : "",
    searchField: searchField,
    rows: rows,
    sortBy: sortBy,
    sortOrder: sortOrder,
    region: calculator?.region === RESPIRATOR_REGION?.EMEA_APAC?"EMEA":calculator?.region,
    respiratorType: calculator?.respiratorType,
    particlePresent: calculator?.particlePresent
  });
  /*------Product Query -----*/

  /*------useEffect Starts -----*/
  useEffect(() => {

    if (searchTermFind?.trim() == "") {
      /* ------when Search field is empty ---- */
      (pageNumber === 1 ?
        setProductList(data?.result?.productList)
        :
        (productList?.length > 0 ?
          setProductList([...productList, ...data?.result?.productList])
          :
          setProductList(data?.result?.productList))  //
      );
      pageNumber === 1 && setVisible((data?.result?.totalRecords < Number(productState?.rows) ? data?.result?.totalRecords : Number(productState?.rows)));
      setTotalRecord(data?.result?.totalRecords);
      /* ----when Search field is empty ends--- */
    }
    else {
      /* ------  when Search field consist of any search Term ---- */
      data?.result?.productList === undefined && updateProductState('pageNumber', 1);
      (pageNumber === 1 ?
        setProductList(data?.result?.productList)
        :
        setProductList([...productList, ...data?.result?.productList])
      );
      pageNumber === 1 && setVisible((data?.result?.totalRecords < Number(productState?.rows) ? data?.result?.totalRecords : Number(productState?.rows)));
      setTotalRecord(data?.result?.totalRecords);
      /* ---- when Search field consist of any search Term ends---- */
    }
    /* eslint-disable */
  }, [data?.result?.productList, searchTermFind]);
  /*------useEffect Completed -----*/

  /* ------- Search Functionality Click : Triggering the Search Button will display product based on search terms ------- */
  const findSearchTerm = (searchTermFind) => {
    if (searchTermFind?.length === 0 || searchTermFind?.length < 3) {
      updateProductState('pageNumber', 1);
    }
    if (searchTermFind?.length === 0 || searchTermFind?.length >= CONFIG_MIN_LETTERS_FOR_SEARCH_CARTRIDGE) {
      setSearch(true);
      setsearchTerm(searchTermFind);
      updateProductState('searchText', searchTermFind);
    }
  };
  /* -------- Search Functionality Ends ---------------------------------------------------------------------------------- */

  /* -------update Product State values ----- */
  const updateProductState = useCallback(
    (key: any, value: any) => {
      if (key) {
        setProductState({ ...productState, [key]: value });
      } else {
        setProductState({ ...productState, ...value });
      }
    },
    [productState]
  );
  /* -------update Product State ends ----- */

  /* ------- Load More Click: Trigerring on Load More button to display more products---------*/
  const LoadMoreProduct = () => {
    updateProductState('searchText', "");
    if (visibleProductList < totalRecord) {
      updateProductState('pageNumber', Number(productState?.pageNumber) + 1);
      if (visibleProductList + Number(productState?.rows) > totalRecord) {
        setVisible(totalRecord);
      }
      else {
        setVisible((prevValue) => prevValue + Number(productState?.rows));
      }
    }
    else {
      setVisible(totalRecord);
    }
  };
  /* ------- Load More Functionality ends----------------------------------------------------*/

  /* -------- Product List Click: Trigerring on product selection  and storing the details ------- */
  const onProductListClick = useCallback((cartridgeSKUValue: string, shortProductDescription: string, productImgUrlValue: string, longProductDescription:string, index: any) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.preventDefault();
        if (cartridgeSKUValue?.length > 0) {
          
          dispatch({
            type: STORE_ACTION_TYPES.CALCULATOR,
            payload: {
              cartridgeSKU: cartridgeSKUValue,
              productDescriptionValue: shortProductDescription,
              productImgUrl: productImgUrlValue,
              productLongDescription: longProductDescription
            },
          });
        }     
      },
    [dispatch]
  );
  /* -------- Product List Functionality Ends------------------------------------------------------ */

  /* --------------- Setting Search Value  ----------------- */
  const setSearchHandler = (event) => {
    setsearchTerm(
      event?.target?.value?.replace(
        REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
        ""
      )
    );
    findSearchTerm(
      event?.target?.value?.replace(
      REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
      ""
    )
    );
  }
  const debounce = (func) => {
    let timer;
    return function (this: any, ...args) {
      const context = this;
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    }
  }
  const optimisedCartridgeSearch = useCallback(debounce(setSearchHandler), []);
  /* --------------- Setting Search Value Ends ----------------- */
  //resize parent iframe
  resizeParent();


  return (
    <div className="cartridge-selection">
      {showError === false ?
        (
          <>
            <div className="heading" aria-hidden="true">
              {t(LABELKEYS_CATRIDGE_STEP.HEADING)}
            </div>

            {/* Calling SerachBox Component */}
            <SearchBox
              showStatus={productList?.length > 0}
              statusText={`${t(LABELKEYS_CATRIDGE_STEP.SHOWING)} ${visibleProductList} ${t(
                LABELKEYS_CATRIDGE_STEP.SHOWING_OF
              )} ${totalRecord} ${t(LABELKEYS_CATRIDGE_STEP.PRODUCT_RESULTS)}`}
              placeholder={t(LABELKEYS_CATRIDGE_STEP.PLACEHOLDER)}
              onChange={optimisedCartridgeSearch}
              onClick={findSearchTerm}
              displayText={t(LABELKEYS_CATRIDGE_STEP.SEARCH)}
              disabled={searchTermFind.length < 3 ? true : false}
            />
            {/* SerachBox Component Ends*/}
            {isLoading && <Loader />}
            {data?.result?.productList === undefined && !isLoading && <NoResults />}
            <div className="products">
              <div className="images-list col-md-12">
                <ul className="col-md-12">
                  {productList?.slice(0, visibleProductList)?.map(
                    (
                      item: {
                        shortDescription: string;
                        sku: string;
                        productImgUrl: string;
                        longDescription:string;
                      },
                      index: any
                    ) => {
                      return (
                        <li
                          key={index}
                          onClick={
                            onProductListClick(
                            item.sku,
                            item.shortDescription,
                            item.productImgUrl,
                            item.longDescription,
                            index
                          )}
                          className={item.sku === cartridgeSKU ? "product-active col-md-3" : "col-md-3"}
                        >
                            <div>
                              <div className="pic">
                              <img src={item?.productImgUrl || FallBackProductImage} onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src=FallBackProductImage;
                                }} alt={item.sku} />
                              </div>
                              
                              <div className="sku fw-bold"> {item.sku}</div>
                              <div className="">{item.shortDescription || ""}</div>
                            </div>      
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>

            {/* LoadMore Starts*/}
            <div className="btn-container">
              {((totalRecord - visibleProductList) > 0) ? (
                <div className="btnmd btn-primary" onClick={LoadMoreProduct}>
                  {t(LABELKEYS_CATRIDGE_STEP.LOAD_MORE)}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {/* LoadMore Ends*/}
          </>
        ) : <ErrorHandling />
      }
    </div>

  );
};

export default CartridgeSelection;

