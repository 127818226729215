/**********************************************************************************************
Summary Section of revirew panel for respirator selction
***********************************************************************************************/
import React from "react";
import "./RespReview.scss";
import { useTranslation } from "react-i18next";
const SummarySection = (props) => {
    const { t } = useTranslation();
    return (
                <div className="list-items">
                    {props.configType?.map((e: any) => {
                        return (
                            <div key={`div${e?.fieldKey}`} className="item">
                                <div className="info"> {t(e?.fieldLabelkey)}</div>
                                {e?.subKey ?
                                    <div className="info"> {
                                            ((e?.blankOnStatus) == props.activeStep || props.calculator[e?.fieldKey]?.length < 1 || props.calculator[e?.fieldKey]===undefined)
                                            ?
                                            "-" 
                                            : 
                                            props.calculator[e?.fieldKey][e?.subKey]
                                        } 
                                    </div>
                                    :
                                    <div className="info"> {
                                        (props.calculator[e?.showOnStatus] === true ? 
                                            props.calculator[e?.fieldKey] :
                                        ((e?.blankOnStatus) == props.activeStep || props.calculator[e?.fieldKey]?.length < 1 || props.calculator[e?.fieldKey] === 0 || props.calculator[e?.fieldKey]===undefined)
                                         ? 
                                         "-" 
                                         : 
                                         props.calculator[e?.fieldKey])} </div>
                                }
                            </div>
                        );
                    })}
                </div>
    );
};

export default SummarySection;

