import React, { useCallback, useState } from "react";
import "./WorkingConditions.scss";
import { LABELS_CALCULATION_CONDITION, LABELKEYS_WORKING_CONDITION, STORE_ACTION_TYPES, ARIA_LABEL, LABELKEYS_CONTAINMENT_REVIEW, WARNING, REGEX_VALIDATION_CHALLENGE_CONCENTRATION } from "../../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WarningModal from "../../../common/WarningModal/WarningModal";
import Modal from "react-bootstrap/Modal";
import ErrorHandling from "../../../common/ErrorHandling/ErrorHandling";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { resizeParent } from "../../../../assets/js/utils";
const WorkingConditions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { concentrationValue, contaminantProductKeyValues, respiratorType } = useSelector((state: any) => state?.respirator?.calculator || {});
    const calculator = useSelector((state: any) => state?.respirator?.calculator || {});
    const { showError } = useSelector((state: any) => state?.respirator?.error || {});
    const { CONFIG_WORKING_CALCULATION_SELECT_FIELD, FIELD_CONFIG_WORKING_CONTAMINANT_UPPER, FIELD_CONFIG_WORKING_CONTAMINANT_LOWER } = useSelector((state: any) => state?.respirator?.config || {});

    //Using this function for setting calculation value 
    const setConcentrationValue = useCallback((storeKeyConcentration: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { [storeKeyConcentration]: e?.target?.value?.replace(
            REGEX_VALIDATION_CHALLENGE_CONCENTRATION.API_INPUT_FIELD_VALIDATION,
            ""
          ) } });
    }, [dispatch]);

    const setValue = useCallback((storeKey: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { [storeKey]: e.target.value, [`${storeKey}Label`]: e.target.options[e.target.selectedIndex].text } });
    }, [dispatch]);

    //Exapand and Collaps row in mobile view
    const [state, setState] = useState({
        respClassName: "wc-details-hide",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "wc-details") {
            setState({
                respClassName: "wc-details-hide",
                iconName: "Up"
            });
        }
        else {
            setState({
                respClassName: "wc-details",
                iconName: "Down"
            });
        }
    };
    //resize parent iframe
    resizeParent();
    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    //Modal Functionality:
    const [show, setShow] = useState(false);

    const showingWarningModal = useCallback(() => {
        setShow(true);
    }, []);

    //Modal Functionality Ends
    //getVaporWarnings
    const getVaporWarnings = useCallback((collWarning) => {
        try {
            return (
                collWarning?.length > 1 ? `<ol type="1">` +
                    collWarning?.map(message => `<li><p>${message}</p></li>`)?.join("") || ""
                    + `</ol>` : `<p>${collWarning?.join("")}</p>` || "");
        }
        catch {
            return "";
        }
    }, []);   
    return (
        <div className="working-conditions">
            {showError === false ?
                (<><div className="heading">{t(LABELKEYS_WORKING_CONDITION.HEADLINE)}</div>
                    <div className="selection-wrapper">
                        <div className="col-md-7 col-sm-12">
                            <div className="sub-head">{contaminantProductKeyValues.vaporName} ({t(LABELKEYS_WORKING_CONDITION.CAS)} {contaminantProductKeyValues.vaporCas})
                                {state.iconName == "Down" ? (
                                    <i className="angle-chevron-down"><BiChevronDown className="angle-chevron-down-icon" onClick={reviewPanelExpand} /></i>
                                ) : (
                                    <i className="angle-chevron-down"><BiChevronUp className="angle-chevron-down-icon" onClick={reviewPanelExpand} /></i>
                                )}
                            </div>
                            <div className={state.respClassName}>
                                {FIELD_CONFIG_WORKING_CONTAMINANT_UPPER?.map((e: any, i: any) => {
                                    return (
                                        <div key={`div${e?.fieldKey}${i}`} className="text-wrapper">
                                            {e?.fieldSource ? 
                                                <div className="info"> {t(e?.fieldLabelkey)} 
                                                {
                                               contaminantProductKeyValues?.[e?.fieldSource] && contaminantProductKeyValues?.[e?.fieldSource]?.toString()?.toLowerCase() != "n/a"?
                                                <span>
                                                  {t(e?.sourceVia)} 
                                                  {contaminantProductKeyValues?.[e?.fieldSource]} 
                                                </span>
                                                :
                                                ""
                                                }
                                                {contaminantProductKeyValues?.[e?.fieldUnit]?.length > 0 && contaminantProductKeyValues?.[e?.fieldUnit]?.toString()?.toLowerCase() != "n/a" ? ` (${contaminantProductKeyValues?.[e?.fieldUnit]})` : ""} </div> : 
                                                <div className="info"> {t(e?.fieldLabelkey)} </div>
                                                }
                                            <div className="info"> {contaminantProductKeyValues[e?.fieldKey] || "-"} </div>
                                        </div>
                                    );
                                })}
                                {FIELD_CONFIG_WORKING_CONTAMINANT_LOWER?.map((e: any, i: any) => {
                                    return (
                                        <div key={`div${e?.fieldKey}${i}`} className="text-wrapper">
                                            <div className="info"> {t(e?.fieldLabelkey)}</div>
                                            {contaminantProductKeyValues[e?.fieldKey]?.length > 0 && e?.warningfieldKey === true ?
                                                <a href="#" className="info" onClick={showingWarningModal} aria-label={t(ARIA_LABEL.VIEW_WARNING)}> {t(LABELKEYS_CONTAINMENT_REVIEW.WARNING)} </a>
                                                :
                                                <div className="info"> {contaminantProductKeyValues[e?.fieldKey] || "-"} </div>
                                            }
                                        </div>
                                    );
                                })}

                            </div> 
                        </div>

                        <div className="col-md-5 col-sm-12">

                            <div className="wc-form">
                                <div className="form-group">
                                    <label htmlFor="ChallengeConcentration">{t(LABELKEYS_WORKING_CONDITION.CHALLENGE_CONCENTRATION)} <span className="mandatory"></span></label>
                                    <input type="number" className="form-control" aria-label={t(ARIA_LABEL.CHALLENGE_CONCENTRATION)} placeholder="" min="0" onPaste={preventPasteNegative} onKeyPress={preventMinus} onKeyDown={blockInvalidChar} value={(concentrationValue || "")} onChange={setConcentrationValue(LABELS_CALCULATION_CONDITION.CONCENTRATION_VALUE)} />
                                    {(!concentrationValue? <small className="form-text color-red" >{t(LABELKEYS_WORKING_CONDITION.PLACEHOLDER_TEXT)}</small> : "")}
                                    {(concentrationValue && !(concentrationValue > 0) ? <small className="form-text color-red" >{t(LABELKEYS_WORKING_CONDITION.ADD_PLACEHOLDER_TEXT)}</small> : "")}
                                </div>

                                {CONFIG_WORKING_CALCULATION_SELECT_FIELD?.map((e: any, i: any) => {
                                    return (
                                            <div key={`div${e?.fieldKey}${i}`} className="form-group">
                                                <label htmlFor="DropDownWorkingCondition">{(e?.fieldKey === "rateValue" ? t(e[`fieldLabelkey${respiratorType}`]) : t(e?.fieldLabelkey ) )}<span className="mandatory"></span></label>
                                                <div>
                                                    <select className="form-control" aria-label={t(e?.fieldariaLabelkey)} value={calculator[e?.fieldKey] || ""} onChange={setValue(e?.fieldKey)} placeholder="" >
                                                        <option value=""></option>
                                                        {(e?.fieldKey === "rateValue" ? e[`fieldvalueoptions${respiratorType}`] : e?.fieldvalueoptions)?.map((option: any, key: number) => {
                                                            return (
                                                                <option key={key} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                                </div>
                                                {!calculator[e?.fieldKey] || calculator[e?.fieldKey] === -1 || calculator[e?.fieldKey]?.toString()?.trim()?.length < 1 ? <small className="form-text color-red" >{t(LABELKEYS_WORKING_CONDITION.PLACEHOLDER_TEXT)}</small> : ""}
                                            </div>
                                    );
                                })}

                            </div>
                        </div>
                    </div></>) : <ErrorHandling />}
            <Modal show={show}>
                <WarningModal showWarningIcon isHtmlMessage
                    messageHeader={t(WARNING.HEADLINE)}
                    message={getVaporWarnings(contaminantProductKeyValues?.primaryWarnings || [])}
                    onClickYes={() => setShow(false)}
                    labelYes={t(WARNING.OK)}
                />
            </Modal>
        </div>
    );
};
export default WorkingConditions;