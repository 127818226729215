import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ImgReusable,
  ImgParr,
  ImgEMEASelected,
  USASelectedImg
} from "../../../../assets/images/respselection/respSelection";
import "./Results.scss";
import WarningModal from "../../../common/WarningModal/WarningModal";
import Modal from "react-bootstrap/Modal";
import { initialState } from "../../../../store/respiratorStore";
import { useSelector } from "react-redux";
import {
  ARIA_LABEL,
  LABELKEYS_CONDITION_REVIEW,
  LABELKEYS_CONTAINMENT_REVIEW,
  LABELKEYS_RESULTS,
  REVIEW_KEY_PANEL_ACTION,
  REVIEW_KEY_PANEL_WARNINGS,
  STORE_ACTION_TYPES,
  WARNING,
} from "../../../../helper/constants";
import Loader from "../../../common/Loader/Loader";
import { useGetResultQuery } from "../../../../services/calculation";
import { useGetRespiratorsQuery } from "../../../../services/respirators";
import { useGetContaminantWarningsQuery } from "../../../../services/contaminantWarnings";
import {
  FallBackProductImage,
  ImgEdit,
} from "../../../../assets/images/cartridge/cartSelection";
import Analytics from "../../../../services/analytics";
import { scrollToParentTop } from "../../../../assets/js/utils";
import { BiChevronDown } from "react-icons/bi";
import ErrorHandling from "../../../common/ErrorHandling/ErrorHandling";
import { resizeParent } from "../../../../assets/js/utils";
const Results = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serviceLifeValue, setServiceLifeValue] = useState("");
  const [respiratorOptions, setRespiratorOptions] = useState<any>([]);
  const [vaporWarnings, setVaporWarnings] = useState<any>([]);
  const [targetRespiratorMask, updateTargetRespiratorMask] =
    useState<any>(undefined);
  const {
    respiratorDescriptionValue,
    productDescriptionValue,
    respiratorCartridgeSKU,
    respiratorImgUrl,
    calculatorType,
    region,
    respiratorType,
    cartridgeSKU,
    productImgUrl,
    concentrationValue,
    productName,
    concentration_Value_1,
    concentration_Value_2,
    contaminantProductKeyValues,
    rateValue,
    temperatureValue,
    respiratorSKU,
    vaporSecondaryWarnings,
  } = useSelector((state: any) => state?.respirator?.calculator || {});
  const calculator = useSelector(
    (state: any) => state?.respirator?.calculator || {}
  );
  const {
    EDIT_SELECTED_STEPS,
    FIELD_CONFIG_RESULTS_CONTAMINANT_SELECT_CARTRIDGE,
    FIELD_CONFIG_RESULTS_CONTAMINANT,
    FIELD_CONFIG_RESULTS_WORKING_CONDITION,
    CALCULATION_TYPES,
    CALCULATION_MAX_PRECISION,
    PAGE_NAVIGATION,
    ANLYTICS_EVENT_TYPES,
    RESPIRATOR_REGION,
    RESPIRATORS_TYPES,
  } = useSelector((state: any) => state?.respirator?.config || {});
  const { showError } = useSelector(
    (state: any) => state?.respirator?.error || {}
  );
  //result calculation call

  const { data: resultServiceLife, isLoading: isResultLoading } = useGetResultQuery(
    calculator?.calculatorType === CALCULATION_TYPES.SERVICE_LIFE
      ? {
        vaporCas: contaminantProductKeyValues?.vaporCas,
        productSku: cartridgeSKU,
        temperature: Number(temperatureValue),
        challengeConcentration: Number(concentrationValue),
        contaminationRate: Number(rateValue),
      }
      : skipToken
  );

  //result calculation call
  const { data: resultRespirators } = useGetRespiratorsQuery(
    calculator?.calculatorType === CALCULATION_TYPES?.VAPORS
      ? {
        productSku: cartridgeSKU,
        pageNumber: 1,
        searchText: "",
        searchField: "",
        rows: -1,
        sortBy: "respirator",
        sortOrder: "asc",
      }
      : skipToken
  );

  //result calculation call
  const { idlh, vaporName, primaryWarnings } =
    contaminantProductKeyValues || {};
  const { data: resultWarnings } = useGetContaminantWarningsQuery({
    challengeConcentration: Number(concentrationValue),
    vaporIdlhPpm: Number(idlh),
    vaporName: vaporName,
  });
  //UseEffect to set warnings
  useEffect(() => {
    let warnings: any = [];
    if (primaryWarnings?.length > 0) {
      warnings = [...primaryWarnings];
    }
    if (resultWarnings?.isSuccess) {
      const validWarningList = resultWarnings?.result || [];
      if (vaporSecondaryWarnings && validWarningList?.length > 0) {
        validWarningList?.forEach((objWarning) => {
          if (vaporSecondaryWarnings[objWarning]) {
            warnings.push(vaporSecondaryWarnings[objWarning]);
          }
        });
      }
    }
    if (warnings?.length > 0) {
      setVaporWarnings(warnings);
    }
  }, [resultWarnings, primaryWarnings, vaporSecondaryWarnings]);

  //getVaporWarnings
  const getVaporWarnings = useCallback(
    (collWarning) => {
      try {
        return collWarning?.length > 1
          ? `<ol type="1">` +
          collWarning
            ?.map((message) =>
              message === vaporSecondaryWarnings.WAR_IDLH
                ? `<li><p class="label-red">${message}</p></li>`
                : `<li><p>${message}</p></li>`
            )
            ?.join("") || "" + `</ol>`
          : collWarning[0] === vaporSecondaryWarnings.WAR_IDLH
            ? `<p class="label-red">${collWarning?.join("")}</p>` || ""
            : `<p>${collWarning?.join("")}</p>` || "";
      } catch {
        return "";
      }
    },
    [vaporSecondaryWarnings.WAR_IDLH]
  );
  //On Reset button Click - will move to first stage with resetting the values
  const onResetButtonClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.LINK_TRACKING,{"buttonName":"Restart Calculator"});
      navigate(PAGE_NAVIGATION.HOME);
      dispatch({
        type: STORE_ACTION_TYPES.CALCULATOR,
        payload: { ...initialState.calculator },
      });
      scrollToParentTop();
      
    },
    [PAGE_NAVIGATION, dispatch, navigate,ANLYTICS_EVENT_TYPES]
  );
  //Result conversion
  const ResultConversation = useCallback(
    (result) => {
      try {
        if (result <= 0) {
          return `0 ${t(LABELKEYS_RESULTS.TIME)}`;
        } else if (result < 60) {
          return Math.floor(result) + ` ${t(LABELKEYS_RESULTS.TIME)}`;
        } else {
          const num = result;
          const hours = num / 60;
          const rhours = Math.floor(hours);
          const minutes = (hours - rhours) * 60;
          const rminutes = Math.round(minutes);
          return (
            rhours +
            ` ${t(LABELKEYS_RESULTS.HOUR)}` +
            " & " +
            rminutes +
            ` ${t(LABELKEYS_RESULTS.TIME)}`
          );
        }
      } catch {
        return result;
      }
    },
    [t]
  );

  //UseEffect to set result calculation value
  useEffect(() => {
    const { respiratorList } = resultRespirators?.result || {};
    if (respiratorList?.length > 0) {
      try {
        setRespiratorOptions(
          respiratorList?.map(
            (objRespirtaor) =>
              objRespirtaor?.respirator?.length > 0 && objRespirtaor?.respirator
          )
        );
      } catch {
        setRespiratorOptions([]);
      }
    }
  }, [resultRespirators?.result]);
  //UseEffect to set result respirators
  useEffect(() => {
    const { result } = resultServiceLife?.result || {};
    if (result && CALCULATION_MAX_PRECISION) {
      const result_servicelife = ResultConversation(result);
      try {
        dispatch({
          type: STORE_ACTION_TYPES.CALCULATOR,
          payload: { estimatedLife: result_servicelife },
        });
        setServiceLifeValue(result_servicelife || "");
      } catch {
        setServiceLifeValue(result || "");
      }
    }
  }, [
    resultServiceLife?.result,
    CALCULATION_MAX_PRECISION,
    ResultConversation,
    dispatch,
  ]);

  //UseEffect for Pdf Warning content
  useEffect(() => {
    const PDFvaporWarning = getVaporWarnings(vaporWarnings);
    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { warningPDFContent: PDFvaporWarning } });
  }, [dispatch, getVaporWarnings, vaporWarnings]);

  //Add analytic while results changes
  useEffect(() => {
    if (serviceLifeValue?.length > 0) {
      Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.RESULTS);
    } else if (respiratorSKU?.length > 0) {
      Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.RESULTS);
    }
    /* eslint-disable */
  }, [serviceLifeValue, respiratorSKU]);

  //function to switch between images for region
  const regionImageSwitch = useMemo(() => {
    switch (region) {
      case RESPIRATOR_REGION?.USA:
        return USASelectedImg;
        break;
      case RESPIRATOR_REGION?.EMEA_APAC:
        return ImgEMEASelected;
        break;
    }
  }, [region]);

  //function to switch between images for respirator type
  const typeImageSwitch = useMemo(() => {
    switch (respiratorType) {
      case RESPIRATORS_TYPES.REUSABLE:
        return ImgReusable;
        break;
      case RESPIRATORS_TYPES.APR:
        return ImgReusable;
        break;
      case RESPIRATORS_TYPES.PAPR:
        return ImgParr;
        break;
    }
  }, [respiratorType, RESPIRATORS_TYPES]);

  //function for respirator selection drop down
  const showRespiratorProduct = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      const filterRespiratorMask =
        resultRespirators?.result?.respiratorList?.filter(
          (respiratorList) => respiratorList.respirator === e.target.value
        );
      dispatch({
        type: STORE_ACTION_TYPES.CALCULATOR,
        payload: { respiratorSKU: e.target.value, respiratorCartridgeSKU: e.target.value, respiratorDescriptionValue: filterRespiratorMask?.length > 0 ? filterRespiratorMask[0]?.description || "" : "" },
      });
      updateTargetRespiratorMask(
        filterRespiratorMask?.length > 0 ? filterRespiratorMask[0] : undefined
      );
    },
    [resultRespirators]
  );

  //On Reset button Click - will move to first stage with resetting the values
  const EditSelectionStep = useCallback(
    (SelectedStep: string, SelectedStepNavigation: string) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        dispatch({
          type: STORE_ACTION_TYPES.CALCULATOR,
          payload: { currentStep: SelectedStep, selectRespiratorCartridge: false },
        });
        navigate(SelectedStepNavigation);
      },
    [
      calculatorType,
      dispatch,
      navigate,
      PAGE_NAVIGATION.ServiceLife.Contaminant_Selection,
      PAGE_NAVIGATION.Vapors.Respirator_Selection,
    ]
  );
  //resize parent iframe
  resizeParent();

  //Modal Functionality:
  const [show, setShow] = useState(false);

  const showingWarningModal = useCallback(() => {
    setShow(true);
  }, []);
  //Modal Functionality Ends
  return (
    <div className="rc-results" id="rc-result">

      <div className="heading" id="heading">
        {t(LABELKEYS_RESULTS.HEADLINE)}
      </div>
      {calculatorType === CALCULATION_TYPES.SERVICE_LIFE && (
        showError === false ? (
          <div className="estimated-service pdf-page1-sec3">
            <div className="title">{t(LABELKEYS_RESULTS.SUB_HEADLINE)}</div>
            <div className="sub-title">
              {serviceLifeValue && serviceLifeValue?.toString()?.length > 0
                && serviceLifeValue?.toString()?.toLowerCase() !== "nan" ?
                `${serviceLifeValue}`:
                isResultLoading ? <Loader />
                  : serviceLifeValue ||
                  `0 ${t(LABELKEYS_RESULTS.TIME)}`}
            </div>
            <p>
              {t(LABELKEYS_CONDITION_REVIEW.CONCENTRATION_NAME)}{" "}(
              {concentrationValue >=
                contaminantProductKeyValues?.exposureLimit ? (
                <span className="source-info">
                  {t(LABELKEYS_CONDITION_REVIEW.CHALLENGE_CONCENTRATION_SPAN_1)}{" "}
                  {contaminantProductKeyValues?.elSource}
                </span>
              ) : (
                <span className="source-info">
                  {t(LABELKEYS_CONDITION_REVIEW.CHALLENGE_CONCENTRATION_SPAN_2)}{""}
                </span>
              )}
              ):{" "}
              {concentrationValue >=
                contaminantProductKeyValues?.exposureLimit ? (
                <span>{concentration_Value_1} {t(LABELKEYS_CONDITION_REVIEW.PPM)}</span>
              ): (
                <span>{concentration_Value_2} {t(LABELKEYS_CONDITION_REVIEW.PPM)}</span>
              )}
            </p>
          </div>
        ) : (
          <ErrorHandling />
        )
      )}
      {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ? (
        <div className="content-wrapper bor-bottom pdf-page1-sec4">
          <div className="column">
            <div className="title expected-vapor-title">
              {t(LABELKEYS_RESULTS.EXPECTED_VAPOR)}{" "}
              <img
                src={ImgEdit}
                className="edit-icon"
                alt="Edit"
                onClick={EditSelectionStep(
                  EDIT_SELECTED_STEPS.ContaminantSelection.fieldKey,
                  EDIT_SELECTED_STEPS.ContaminantSelection.navigationUrl[0]
                )}
              />
            </div>
            <div className="cal-details">
              {FIELD_CONFIG_RESULTS_CONTAMINANT?.map((e: any) => {
                return (
                  <div key={`div${e?.fieldKey}`} className="text-wrapper">
                    {e?.fieldSource ?
                      <div className="info"> {t(e?.fieldLabelkey)}
                        {
                         contaminantProductKeyValues?.[e?.fieldSource] && contaminantProductKeyValues?.[e?.fieldSource]?.toString()?.toLowerCase() != "n/a"?
                            <span className="fw-500">
                              {t(e?.sourceVia)}
                              {contaminantProductKeyValues?.[e?.fieldSource]}
                            </span>
                            :
                            ""
                        }
                        {contaminantProductKeyValues?.[e?.fieldUnit]?.length > 0 && contaminantProductKeyValues?.[e?.fieldUnit]?.toString()?.toLowerCase() != "n/a" ? ` (${contaminantProductKeyValues?.[e?.fieldUnit]})` : ""} </div> :
                      <div className="info"> {t(e?.fieldLabelkey)} </div>
                    }
                    {e?.warningfieldKey === true ? (
                      vaporWarnings?.length > 0 ? (
                        <a
                          href="#"
                          className="info view-warning"
                          onClick={showingWarningModal}
                          aria-label={t(ARIA_LABEL.VIEW_WARNING)}
                        >
                          {" "}
                          {t(LABELKEYS_CONTAINMENT_REVIEW.WARNING)}{" "}
                        </a>
                      ) : (
                        "-"
                      )
                    ) : (
                      <div className="info">
                        {" "}
                        {contaminantProductKeyValues[e?.fieldKey] || "-"}{" "}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="column">
            <div className="title working-condition-title">
              {t(LABELKEYS_RESULTS.WORKING_CONDITION)}{" "}
              <img
                src={ImgEdit}
                className="edit-icon"
                alt="Edit"
                onClick={EditSelectionStep(
                  EDIT_SELECTED_STEPS.WorkingCondition.fieldKey,
                  EDIT_SELECTED_STEPS.WorkingCondition.navigationUrl[0]
                )}
              />
            </div>
            <div className="cal-details">
              {FIELD_CONFIG_RESULTS_WORKING_CONDITION?.map((e: any) => {
                return (
                  <div key={`div${e?.fieldKey}`} className="text-wrapper">
                    {e?.brekthroughavailable?.length > 0 ? (
                      concentrationValue >=
                        contaminantProductKeyValues?.exposureLimit ? (
                        <>
                          <div className="info">
                            {" "}
                            {t(e?.fieldLabelkey)}{" "}
                            <span>
                              ( {t(e?.fieldSpankey)}{" "}
                              )
                            </span>
                          </div>
                          <div className="info">
                            {" "}
                            {calculator[e?.fieldKeyValue] || "-"}{" "}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="info">
                            {" "}
                            {t(e?.fieldLabelkey)}{" "}
                            <span>
                              ( {t(e?.fieldSpankey2)}{" "}
                              )
                            </span>
                          </div>
                          <div className="info">
                            {" "}
                            {calculator[e?.fieldKeyValue2] || "-"}{" "}
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        <div className="info">
                          {" "}
                          {(e?.fieldKey === "rateValue" ? t(e[`fieldLabelkey${respiratorType}`]) : t(e?.fieldLabelkey))}{" "}{" "}
                          <span>{t(e?.fieldSpankey)}</span>
                        </div>
                        <div className="info">
                          {" "}
                          {calculator[e?.fieldKeyValue] || "-"}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="content-wrapper bor-bottom pdf-page1-sec4">
          <div className="column">
            <div className="title expected-vapor-title">
              {t(LABELKEYS_RESULTS.EXPECTED_VAPOR)}{" "}
              <img
                src={ImgEdit}
                className="edit-icon"
                alt="Edit"
                onClick={EditSelectionStep(
                  EDIT_SELECTED_STEPS.ContaminantSelection.fieldKey,
                  EDIT_SELECTED_STEPS.ContaminantSelection.navigationUrl[1]
                )}
              />
            </div>
            <div className="cal-details">
              {FIELD_CONFIG_RESULTS_CONTAMINANT_SELECT_CARTRIDGE?.map(
                (e: any) => {
                  return (
                    <div key={`div${e?.fieldKey}`} className="text-wrapper">
                      {e?.fieldSource ?
                        <div className="info"> {t(e?.fieldLabelkey)}
                          {
                            contaminantProductKeyValues?.[e?.fieldSource] && contaminantProductKeyValues?.[e?.fieldSource]?.toString()?.toLowerCase() != "n/a" ?
                              <span className="fw-500">
                                {t(e?.sourceVia)}
                                {contaminantProductKeyValues?.[e?.fieldSource]}
                              </span>
                              :
                              ""
                          }
                          {contaminantProductKeyValues?.[e?.fieldUnit]?.length > 0 && contaminantProductKeyValues?.[e?.fieldUnit]?.toString()?.toLowerCase() != "n/a" ? ` (${contaminantProductKeyValues?.[e?.fieldUnit]})` : ""} </div> :
                        <div className="info"> {t(e?.fieldLabelkey)} </div>
                      }
                      {e?.warningfieldKey === true ? (
                        vaporWarnings?.length > 0 ? (
                          <a
                            href="#"
                            className="info view-warning"
                            onClick={showingWarningModal}
                            aria-label={t(ARIA_LABEL.VIEW_WARNING)}
                          >
                            {" "}
                            {t(LABELKEYS_CONTAINMENT_REVIEW.WARNING)}{" "}
                          </a>
                        ) : (
                          "-"
                        )
                      ) : (
                        <div className="info">
                          {" "}
                          {contaminantProductKeyValues[e?.fieldKey] || "-"}{" "}
                        </div>
                      )}

                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="column">
            <div className="title working-condition-title">
              {t(LABELKEYS_RESULTS.CARTRIDGE_SELECTION)}
              <img
                src={ImgEdit}
                className="edit-icon"
                alt="Edit"
                onClick={EditSelectionStep(
                  EDIT_SELECTED_STEPS.ProductSelection.fieldKey,
                  EDIT_SELECTED_STEPS.ProductSelection.navigationUrl[1]
                )}
              />
            </div>
            <div>
              <div className="your-cartridge">
                <div className="product">
                  {productImgUrl ? (
                    <img
                      src={productImgUrl}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = FallBackProductImage;
                      }}
                      alt={cartridgeSKU}
                    />
                  ) : (
                    <img src={FallBackProductImage} alt={cartridgeSKU} />
                  )}
                </div>
                <div className="product">
                  <div className="details">
                    <p>{productName}</p>
                    <p className="fw-bold">{cartridgeSKU}</p>
                    <p>{productDescriptionValue}</p>
                  </div>
                </div>
              </div>
              {targetRespiratorMask && (
                <div className="your-cartridge">
                  <div className="product">
                    {targetRespiratorMask?.respiratorImgUrl ? (
                      <img
                        src={targetRespiratorMask?.respiratorImgUrl}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = FallBackProductImage;
                        }}
                        alt={respiratorCartridgeSKU}
                      />
                    ) : (
                      <img
                        src={FallBackProductImage}
                        alt={respiratorCartridgeSKU}
                      />
                    )}
                  </div>
                  <div className="product">
                    <div className="details">
                      <p className="fw-bold">
                        {targetRespiratorMask.respirator}
                      </p>
                      <p>
                        {targetRespiratorMask.description}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="form-group respiratorSelection"
                id="respiratorSelection"
              >
                <label htmlFor="respiratorSelection">
                  {t(LABELKEYS_RESULTS.RESPIRATOR_OPTION_CARTRIDGE_SELECTION)}
                </label>
                <div className="text-wrapper">
                  <select
                    className="form-control"
                    aria-label={t(cartridgeSKU)}
                    onChange={showRespiratorProduct}
                  >
                    <option value="">
                      {t(
                        LABELKEYS_RESULTS.RESPIRATOR_OPTION_CARTRIDGE_SELECTION_LABEL
                      )}
                    </option>
                    {respiratorOptions?.map((val: String, key: number) => {
                      return (
                        <option key={key} value={String(val)}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <i className="angle-down-arrow">
                  <BiChevronDown className="angle-down-icon" />
                </i>
                <p className="small">
                  {t(LABELKEYS_RESULTS.RESPIRATOR_OPTION_COMPATIBLE)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper content-pdf-imgaes pdf-page1-sec2 justify-content-center" id="content-pdf-imgaes-top">
        <div className="column">
          <div className="title region-respirator-title">
            {t(LABELKEYS_RESULTS.REGION_AND_RESPIRATOR)}
            <img
              src={ImgEdit}
              className="edit-icon"
              alt="Edit"
              onClick={
                calculatorType === CALCULATION_TYPES.SERVICE_LIFE
                  ? EditSelectionStep(
                    EDIT_SELECTED_STEPS.RespiratorSelection.fieldKey,
                    EDIT_SELECTED_STEPS.RespiratorSelection.navigationUrl[0]
                  )
                  : EditSelectionStep(
                    EDIT_SELECTED_STEPS.RespiratorSelection.fieldKey,
                    EDIT_SELECTED_STEPS.RespiratorSelection.navigationUrl[1]
                  )
              }
            />
          </div>
          <div className="region-list">
            <ul>
              {region?.length > 0 ? (
                <li>
                  <div className="pic">
                    <img src={regionImageSwitch} alt={region} />
                  </div>
                  <p className="sub-title emea-text">{region}</p>
                </li>
              ) : (
                ""
              )}
              {respiratorType?.length > 0 ? (
                <li>
                  <div className="pic">
                    <img src={typeImageSwitch} alt={respiratorType} />
                  </div>
                  <p className="sub-title">{respiratorType}</p>
                </li>
              ) : (
                <p className="no-data">
                  {t(REVIEW_KEY_PANEL_WARNINGS.NO_REGION_RESPIRATOR)}
                </p>
              )}
            </ul>
          </div>
        </div> 
          {calculatorType === CALCULATION_TYPES.SERVICE_LIFE && (
            <div className="column">
              <div className="title cartage-selection-title">
              {t(LABELKEYS_RESULTS.CARTRIDGE_SELECTION)}{" "}
              <img
                src={ImgEdit}
                className="edit-icon"
                alt="Edit"
                onClick={
                  calculatorType === CALCULATION_TYPES.SERVICE_LIFE
                    ? EditSelectionStep(
                      EDIT_SELECTED_STEPS.ProductSelection.fieldKey,
                      EDIT_SELECTED_STEPS.ProductSelection.navigationUrl[0]
                    )
                    : EditSelectionStep(
                      EDIT_SELECTED_STEPS.ProductSelection.fieldKey,
                      EDIT_SELECTED_STEPS.ProductSelection.navigationUrl[1]
                    )
                }
              />
            </div>
            <div className="your-cartridge">
            <div className="product">
              {productImgUrl ? (
                <img
                  src={productImgUrl}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = FallBackProductImage;
                  }}
                  alt={cartridgeSKU}
                />
              ) : (
                <img src={FallBackProductImage} alt={cartridgeSKU} />
              )}
            </div>
            <div className="product">
              <div className="details">
                <p>{productName}</p>
                <p className="fw-bold">{cartridgeSKU}</p>
                <p>{productDescriptionValue}</p>
              </div>
            </div>
          </div>
            {respiratorCartridgeSKU?.length > 0 && (
              <div className="your-cartridge">
                <div className="product">
                  {respiratorImgUrl ? (
                    <img
                      src={respiratorImgUrl}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = FallBackProductImage;
                      }}
                      alt={respiratorCartridgeSKU}
                    />
                  ) : (
                    <img
                      src={FallBackProductImage}
                      alt={respiratorCartridgeSKU}
                    />
                  )}
                </div>
                <div className="product">
                  <div className="details">
                    <p className="fw-bold">{respiratorCartridgeSKU}</p>
                    <p>{respiratorDescriptionValue}</p>
                  </div>
                </div>
              </div>
            )}
            </div>
          )}
        
      </div>
      <div className="btn-container pt-4 btn-restart-calculator">
        <div id="btn-restart-calculator" onClick={onResetButtonClick} className="btn btn-primary mb-4">
          {t(REVIEW_KEY_PANEL_ACTION.RESTART_CALCULATOR)}
        </div>
      </div>

      <Modal show={show}>
        <WarningModal
          showWarningIcon
          isHtmlMessage
          messageHeader={t(WARNING.HEADLINE)}
          message={getVaporWarnings(vaporWarnings)}
          onClickYes={() => setShow(false)}
          labelYes={t(WARNING.OK)}
        />
      </Modal>
    </div>
  );
};
export default Results;
