/**********************************************************************************************
ProgressStepper.tsx - Progrss bar componenet to show the progress
***********************************************************************************************/
import React from "react";
import StepNavigation from "./StepNavigation";
import "../../dashboard/pages/RespiratorSteps/RespiratorSteps.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./ProgressBar.scss";
import { useTranslation } from "react-i18next";
import { PROGRESS_BAR } from "../../../helper/constants";
const ProgressStepper:React.FC<any> = (props) => {
  const { t } = useTranslation();
  const labelArray = props?.step || [];
  const activeStage = props?.completed || "";
  const activeIndex = labelArray?.indexOf(activeStage) || 0;
  return (
    <div>
      <div className="steps-responsive">
        <div className="content">{t(PROGRESS_BAR.SHOW)} {activeIndex + 1} {t(PROGRESS_BAR.OF)} {labelArray?.length}</div>
        <div className="bar"><ProgressBar now={((activeIndex+1)/labelArray?.length)*100} /></div>
      </div>
      <StepNavigation labelArray={labelArray} activeIndex={activeIndex} />
    </div>
  );
};
export default ProgressStepper;
