/****************************************************
App.tsx - Root Page of the application
*****************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { useGetConfigurationsQuery } from './services/configurations';
import ErrorFallback from "./components/masterpage/ErrorBoundary";
import { ErrorBoundary } from 'react-error-boundary';
import MainContent from "./components/masterpage/MainContent";
import './App.scss';
import { API_PATHS, STORE_ACTION_TYPES, ERROR_MESSAGES } from "./helper/constants";
const App = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const { data, error } = useGetConfigurationsQuery(API_PATHS.CONFIGURATIONS);
  //init store
  useEffect(() => {
    if (data?.isSuccess) {
      const resources = data?.result;
      dispatch({ type: STORE_ACTION_TYPES.INIT, payload: resources });      
    }
    else if (!data?.isSuccess && error) {
      setErrorMessage(ERROR_MESSAGES.CONFIG_NOT_FOUND);
    }
  }, [data, error, dispatch]);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="App">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {errorMessage?.length > 0 ? <>{errorMessage}</> : <MainContent />}
        </ErrorBoundary>
      </div></ErrorBoundary>
  );
};
export default App;
