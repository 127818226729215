import React, { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./RespReview.scss";
import Modal from "react-bootstrap/Modal";
import { ImgWarning } from "../../../../assets/images/cartridge/cartSelection";
import WarningModal from "../../../common/WarningModal/WarningModal";
import Analytics from "../../../../services/analytics";
import { RESPIRATOR_STEPS, STORE_ACTION_TYPES, LABELKEYS_CONTAINMENT_REVIEW, REVIEW_KEY_PANEL_ACTION, WARNING, CALCULATION_TYPES } from "../../../../helper/constants";
import SummarySection from "./SummarySection";
import { scrollToParentTop } from "../../../../assets/js/utils";
const ContainmentReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { respiratorCartridgeSKU, selectRespiratorCartridge, activeStep, contaminantProductKeyValues, isVaporWarningAgreed, calculatorType,currentStep  } = useSelector((state: any) => state?.respirator?.calculator || {});
    const calculator = useSelector((state: any) => state?.respirator?.calculator || {});
    const { FIELD_CONFIG_RESULTS, FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE, PAGE_NAVIGATION,ANLYTICS_EVENT_TYPES, FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR } = useSelector((state: any) => state?.respirator?.config || {});
    
    //Flag for checking if any calculation done or not 
    const isButtonActive = useMemo(() => {
        if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)
            {
                return (isVaporWarningAgreed && contaminantProductKeyValues?.vaporName != undefined && contaminantProductKeyValues?.vaporCas != undefined);
            }
            else if(calculatorType === CALCULATION_TYPES.VAPORS)
            {
                return (contaminantProductKeyValues?.vaporName != undefined && contaminantProductKeyValues?.vaporCas != undefined);
            }
    }, [calculatorType, isVaporWarningAgreed, contaminantProductKeyValues?.vaporName, contaminantProductKeyValues?.vaporCas]);
    
      
    //On continue Click - will move to next stage if region and respirator filled  
    const onContinueButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if (isButtonActive) {
            if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)
            {
                if (activeStep === RESPIRATOR_STEPS.CONTAINMENT_SELECTION) {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION, currentStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION } });
                   
                }
                else {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.CARTRIDGE_SELCTION } });
                   
                }

                if(currentStep === RESPIRATOR_STEPS.CONTAINMENT_SELECTION ){
                    navigate(PAGE_NAVIGATION.ServiceLife.Product_Selection);
                    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.CONTAMINANT_SELECTION);
                }
            }
            else if(calculatorType === CALCULATION_TYPES.VAPORS)
            {
                if (activeStep === RESPIRATOR_STEPS.CONTAINMENT_SELECTION) {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { activeStep: RESPIRATOR_STEPS.RESPIRATOR_SELECTION, currentStep: RESPIRATOR_STEPS.RESPIRATOR_SELECTION } });
                }
                else {
                    dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.RESPIRATOR_SELECTION } });
                }

                if(currentStep === RESPIRATOR_STEPS.CONTAINMENT_SELECTION ){
                    navigate(PAGE_NAVIGATION.Vapors.Respirator_Selection);
                    Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.CONTAMINANT_SELECTION);
                }
            }
        }

    }, [isButtonActive, calculatorType, activeStep,PAGE_NAVIGATION.Vapors.Respirator_Selection,PAGE_NAVIGATION.ServiceLife.Product_Selection,currentStep,ANLYTICS_EVENT_TYPES,navigate,dispatch]);
    
    //On back button Click - will move to prev stage if region and respirator filled  
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if(calculatorType === CALCULATION_TYPES.SERVICE_LIFE)
        {
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { currentStep: RESPIRATOR_STEPS.RESPIRATOR_SELECTION, showError: false } });
            navigate(PAGE_NAVIGATION.ServiceLife.Respirator_Selection);
        }
        else if(calculatorType === CALCULATION_TYPES.VAPORS)
        {
            navigate(PAGE_NAVIGATION[calculatorType].Terms_Conditions);
        }
    
    }, [calculatorType,PAGE_NAVIGATION, dispatch, navigate]);

    //Modal Functionality:
    const [show, setShow] = useState(false);
    const showingWarningModal = () => {
        setShow(true);
    };

    //Modal OnClose
    const setVaporDisagree = useCallback(() => {
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { isVaporWarning: false, isVaporWarningAgreed: false } });
        setShow(false);
    }, [dispatch]);
    //Modal OnOpen 
    const setVaporAgree = useCallback(() => {
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { isVaporWarning: true, isVaporWarningAgreed: true } });
        setShow(false);
    }, [dispatch]);

    //Modal Functionality Ends

    //On AngleUp and Down Arrow Event - will expand and collapse Review Panel
    const [state, setState] = useState({
        respClassName: "resp-review-mobile",
        downAngleiconClass :"resp-acrodian",
        iconName: "Up",
    });
    const reviewPanelExpand = () => {
        if (state.respClassName === "resp-review-mobile") {
            setState({
                respClassName: "expand-resp-panel contaminant-resp-panel",
                downAngleiconClass :"resp-acrodian-new",
                iconName: "Down"
            });
        }
        else {
            setState({
                respClassName: "resp-review-mobile",
                downAngleiconClass :"resp-acrodian",
                iconName: "Up"
            });
        }
    };
    //getVaporWarnings
    const getVaporWarnings = useCallback((collWarning) => {
        try {
            return (
                collWarning?.length > 1 ? `<ol type="1">` +
                    collWarning?.map(message => `<li><p>${message}</p></li>`)?.join("") || ""
                    + `</ol>` : `<p>${collWarning?.join("")}</p>` || "");
        }
        catch {
            return "";
        }
    }, []);
    return (
        <div className="resp-review">
            <div className={state.respClassName}>
                <div className={state.downAngleiconClass}>
                    {state.iconName == "Down" ? (
                        <BiChevronDown className="review-expand-icon" onClick={reviewPanelExpand} />
                    ) : (
                        <BiChevronUp className="review-expand-icon" onClick={reviewPanelExpand} />
                    )}
                </div>
                <div className="btn-container cta-mobile">
                    <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto" : "btn btn-disabled max-width mx-auto"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
                </div>
                <div className="title">{t(LABELKEYS_CONTAINMENT_REVIEW.HEADING)}</div>
                <div className="list-items bor-bottom">
                    <div className="item">
                        <div className="info">{t(LABELKEYS_CONTAINMENT_REVIEW.ORGANIC_VAPOUR)}</div>
                        <div className="info">{contaminantProductKeyValues?.vaporName || "-"}</div>
                    </div>
                    <div className="item">
                        <div className="info">{t(LABELKEYS_CONTAINMENT_REVIEW.CAS)}</div>
                        <div className="info">{contaminantProductKeyValues?.vaporCas || "-"}</div>
                    </div>
                    <div className="item">
                        {(contaminantProductKeyValues?.primaryWarnings)?.length > 0 &&
                            (<>
                                <div className="info"><img src={ImgWarning} alt="Warning" />
                                </div>
                                <div className="info">
                                    <a href="#" onClick={showingWarningModal}>
                                        {t(LABELKEYS_CONTAINMENT_REVIEW.WARNING)}
                                    </a>
                                </div>
                            </>)
                        }
                    </div>
                </div>
                <SummarySection 
                    configType={calculatorType ===  CALCULATION_TYPES.SERVICE_LIFE
                        ?
                        (respiratorCartridgeSKU?.length <= 0 && selectRespiratorCartridge === false ? FIELD_CONFIG_RESULTS : FIELD_CONFIG_RESULTS_SELECT_RESPIRATOR)
                        : 
                        FIELD_CONFIG_RESULTS_SELECT_CARTRIDGE
                    }
                    activeStep={activeStep}
                    calculator={calculator}
                />
                {/* Set Warning Message When user disagree warning Pop up */}
                {calculatorType===CALCULATION_TYPES.SERVICE_LIFE && ((contaminantProductKeyValues?.primaryWarnings)?.length > 0 && !isVaporWarningAgreed ?
                    <div className="warning-not-accepted">
                        <span>{t(WARNING.WARNING_REVIEW_HEADLINE)}</span>
                        {t(WARNING.WARNING_REVIEW_SUBHEADLINE)}
                        <div className="pt-2 info">
                            {(contaminantProductKeyValues?.primaryWarnings)?.length > 0 ? <a href="#" onClick={showingWarningModal}>{t(LABELKEYS_CONTAINMENT_REVIEW.WARNING)}</a> : "-"}
                        </div>
                    </div>
                    :
                    <p></p>)
                }
                <div className="btn-container pt-2 cta-desktop">
                    <div onClick={onContinueButtonClick} className={isButtonActive ? "btn btn-secondary max-width mx-auto mb-3" : "btn btn-disabled max-width mx-auto mb-3"}>{t(REVIEW_KEY_PANEL_ACTION.CONTINUE)}</div>
                    <div onClick={onBackButtonClick} className="btn btn-primary max-width mx-auto">{t(REVIEW_KEY_PANEL_ACTION.BACK)}</div>
                </div>
                <Modal show={show}>
                {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ?
                    <WarningModal showWarningIcon  isHtmlMessage
                        messageHeader={t(WARNING.HEADLINE)}
                        message={getVaporWarnings(contaminantProductKeyValues?.primaryWarnings || [])}
                        onClickNo={setVaporDisagree}
                        onClickYes={setVaporAgree}
                        labelNo={t(WARNING.DISAGREE)}
                        labelYes={t(WARNING.AGREE)}
                    /> 
                    :
                    <WarningModal showWarningIcon isHtmlMessage
                    messageHeader={t(WARNING.HEADLINE)}
                    message={getVaporWarnings(contaminantProductKeyValues?.primaryWarnings || [])}
                    onClickYes={setVaporDisagree}
                    labelYes={t(WARNING.OK)}
                    />  
                }
                </Modal>
            </div>
        </div>
    );
};

export default ContainmentReview;