/**********************************************************************************************
StepSelection.tsx - To manage steps component
***********************************************************************************************/
import React from "react";
import RespiratorSelection from "../RespiratorSelection/RespiratorSelection";
import CartridgeSelection from "../CartridgeSelection/CartridgeSelection";
import ContaminantSelection from "../ContaminantSelection/ContaminantSelection";
import WorkingConditions from "../WorkingConditions/WorkingConditions";
import { RESPIRATOR_STEPS } from "../../../../helper/constants";
import Results from "../Results/Results";
import { useSelector } from "react-redux";
import RespiratorCartridgeSelection from "../CartridgeSelection/RespiratorCartridgeSelection";
const StepSelection: React.FC<{ currentStep: string, calculatorType: string }> = ({ currentStep }) => {
    const {selectRespiratorCartridge } = useSelector((state: any) => state?.respirator?.calculator || {});  
    return (<>
        {(() => {
            switch (currentStep) {
                case RESPIRATOR_STEPS.RESPIRATOR_SELECTION:
                    return <RespiratorSelection />;
                case RESPIRATOR_STEPS.CARTRIDGE_SELCTION:
                    if (selectRespiratorCartridge === false)
                    {
                        return <CartridgeSelection/>;
                    }
                    else
                    {
                        return <RespiratorCartridgeSelection />;
                    }
                case RESPIRATOR_STEPS.CONTAINMENT_SELECTION:
                    return <ContaminantSelection />;
                case RESPIRATOR_STEPS.WORKING_CONDITION:
                    return <WorkingConditions />;
                case RESPIRATOR_STEPS.RESULTS:
                    return <Results/>;
            }
        })()}</>
    );
};
export default StepSelection;